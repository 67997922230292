import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";

const TableHead = ({ columns, onSort }) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const handleSort = (accessor) => {
    let direction = "asc";
    if (sortConfig.key === accessor && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key: accessor, direction });
    onSort(accessor, direction);
  };

  return (
    <thead>
      <tr>
        {columns.map((col) => {
          if (col?.type && col.type === "number") {
            return <td key={col.accessor}>{[col.label]}</td>;
          }
          return (
            <th
              onClick={() => handleSort(col.accessor)}
              key={col.accessor}>
              <span style={{ cursor: "pointer", color: "#2d3359" }}>
                {col.label}
                {sortConfig.key === col.accessor && (
                  <span style={{ marginLeft: "2px" }}>{sortConfig.direction === "asc" ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}</span>
                )}
              </span>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHead;
