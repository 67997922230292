import { FormControlLabel, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { CgClose } from "react-icons/cg";
import { toast } from "react-toastify";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import * as yup from "yup";
import { getAllEnums, offerDetailsApi } from "../../apilist/ApiLists";
import AppColor from "../../components/shared/appColor/AppColors";
import { AxiosInstance } from "../../config/AxiosConfig";
import IntlMessages from "../../util/IntlMessages";
import ModelSelfFooter from "../shared/Model/ModelSelfFooter";
import ToastMessage from "../shared/ToastMessage";

const AddOfferDetail = ({ openDialog, setOpenDialog, offerDetailsData, reloadFunction, setReloadDataList }) => {
  AddOfferDetail.propTypes = {
    openDialog: PropTypes.bool,
    setOpenDialog: PropTypes.func,
    offerDetailsData: PropTypes.object,
    reloadFunction: PropTypes.func,
    setReloadDataList: PropTypes.func,
  };
  const offerDetailDataById = offerDetailsData && offerDetailsData.id;

  const [durationTypes, setDurationTypes] = useState([]);
  const [planTypes, setPlanTypes] = useState([]);

  let validationSchema = yup.object().shape({
    offerName: yup.string().trim().required(""),
    planType: yup.string().trim().required(""),
    durationType: yup.string().trim().required(""),
    startDate: yup.string().trim().required(""),
    endDate: yup.string().trim().required(""),
    discountPercent: yup.string().trim().required(""),
    offerDescription: yup.string().trim().required(""),
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      offerName: "",
      offerDescription: "",
      planType: "",
      durationType: "",
      offerMessage: "",
      startDate: "",
      endDate: "",
      discountPercent: "",
      active: true,
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema: validationSchema,
  });

  const renderErrorMessage = (field) => {
    return formik.touched[field] && <div className="text-danger">{formik.errors[field]}</div>;
  };

  const CreateOffer = async (values) => {
    await AxiosInstance.post(`/${offerDetailsApi}`, values).then((response) => {
      setReloadDataList((prev) => !prev);
      setOpenDialog(false);
    });
  };

  const UpdateOffer = async (values) => {
    await AxiosInstance.put(`/${offerDetailsApi}/${offerDetailDataById}`, values).then((response) => {
      setOpenDialog(false);
      reloadFunction && reloadFunction(0, 10, "");
    });
  };

  const handleSubmit = async (values) => {
    if (offerDetailDataById) {
      UpdateOffer(values);
    } else {
      CreateOffer(values);
    }
  };

  useEffect(() => {
    if (offerDetailsData) {
      formik.setValues(offerDetailsData);
    }
  }, [offerDetailsData]);

  const toggleChecked = (e) => {
    formik.setFieldValue("active", e.target.checked);
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const allEnums = async () => {
    await AxiosInstance.get(`${getAllEnums}`).then((response) => {
      setDurationTypes(response.planDurationTypeEnum);
      setPlanTypes(response.planTypeEnum);
    });
  };

  useEffect(() => {
    allEnums();
  }, []);

  if (formik.isSubmitting && formik.isValidating && !formik.isValid) {
    toast.error("Please fill all the mandatory fields", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }

  return (
    <>
      <ToastMessage />
      <ModelSelfFooter
        open={openDialog}
        heading={offerDetailDataById ? <IntlMessages id="offerDetail.update" /> : <IntlMessages id="offerDetail.add" />}
        closePopUp={
          <CgClose
            onClick={() => setOpenDialog(false)}
            className="popUpCloseIcon"
          />
        }
        closeFn={() => setOpenDialog(false)}
        maxWidth="xs"
        footer={<span className="DisplayNone">.</span>}>
        <Form
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          className="mdForm"
          onSubmit={formik.handleSubmit}>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="offerName">
                  <IntlMessages id="text.offerDetail.offerName" />
                </Label>
                <Input
                  type="text"
                  id="offerName"
                  name="offerName"
                  value={formik.values.offerName}
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.offerName && !formik.values.offerName}
                  onBlur={formik.handleBlur}
                />
                {renderErrorMessage("offerName")}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="planType"
                  className="requiredField">
                  <IntlMessages id="text.offerDetail.planType" />
                </Label>
                <Input
                  type="select"
                  id="planType"
                  name="planType"
                  value={formik.values.planType}
                  onChange={formik.handleChange}
                  className="input-select-field-css"
                  invalid={formik.touched.planType && !formik.values.planType}
                  onBlur={formik.handleBlur}>
                  <option value="">-- Select --</option>
                  {Object.values(planTypes).map((value) => (
                    <option value={value}>{value}</option>
                  ))}
                </Input>
                {renderErrorMessage("planType")}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="enabled"
                  className="requiredField">
                  <IntlMessages id="text.offerDetail.durationType" />
                </Label>
                <Input
                  type="select"
                  id="durationType"
                  name="durationType"
                  value={formik.values.durationType}
                  onChange={formik.handleChange}
                  className="input-select-field-css"
                  invalid={formik.touched.durationType && !formik.values.durationType}
                  onBlur={formik.handleBlur}>
                  <option value="">-- Select --</option>
                  {Object.values(durationTypes).map((value) => (
                    <option value={value}>{value}</option>
                  ))}
                </Input>
                {renderErrorMessage("durationType")}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="startDate"
                  className="requiredField">
                  <IntlMessages id="text.offerDetail.startDate" />
                </Label>
                <Input
                  type="date"
                  id="startDate"
                  name="startDate"
                  value={formik.values.startDate}
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.startDate && !formik.values.startDate}
                  onBlur={formik.handleBlur}
                />
                {renderErrorMessage("startDate")}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="endDate"
                  className="requiredField">
                  <IntlMessages id="text.offerDetail.endDate" />
                </Label>
                <Input
                  type="date"
                  id="endDate"
                  name="endDate"
                  value={formik.values.endDate}
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.endDate && !formik.values.endDate}
                  onBlur={formik.handleBlur}
                />
                {renderErrorMessage("endDate")}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="discountPercent"
                  className="requiredField">
                  <IntlMessages id="text.offerDetail.discountPercent" />
                </Label>
                <Input
                  type="text"
                  id="discountPercent"
                  name="discountPercent"
                  value={formik.values.discountPercent}
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.discountPercent && !formik.values.discountPercent}
                  onBlur={formik.handleBlur}
                />
                {renderErrorMessage("discountPercent")}
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <FormGroup>
                <Label
                  htmlFor="offerDescription"
                  className="requiredField">
                  <IntlMessages id="text.offerDetail.offerDescription" />
                </Label>
                <Input
                  type="text"
                  id="offerDescription"
                  name="offerDescription"
                  value={formik.values.offerDescription}
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.offerDescription && !formik.values.offerDescription}
                  onBlur={formik.handleBlur}
                />
                {renderErrorMessage("offerDescription")}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="offerStatus"
                  className="statusLabel">
                  Offer Status
                </Label>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={formik.values.active}
                      onChange={toggleChecked}
                    />
                  }
                  className="switchButton"
                  label={formik.values.active ? <b>True</b> : <b>False</b>}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="offerMessage">
                  <IntlMessages id="text.offerDetail.offerMessage" />
                </Label>
                <Input
                  type="text"
                  id="offerMessage"
                  name="offerMessage"
                  value={formik.values.offerMessage}
                  onChange={formik.handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="FooterForm">
            <Button
              variant="contained"
              color="outline-secondary"
              onClick={() => setOpenDialog(false)}
              className="buttonSpacing">
              <IntlMessages id={`button.text.cancel`} />
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              style={{ backgroundImage: `${AppColor.BackgroundImage}` }}
              className="buttonSpacing">
              <IntlMessages id={`button.text.save`} />
            </Button>
          </Row>
        </Form>
      </ModelSelfFooter>
    </>
  );
};
export default AddOfferDetail;
