export default {
  defaultHeader: "HRMS Admin",

  // Titles on Dashboard
  "dashboard.titles": "Dashboard",
  "company.detail.title": "Company Detail",
  "tenants.title": "Tenants",
  "subscription.plan.title": "Plans",
  "company.subscription.title": "Company Subscription",
  "company.user.title": "Company User",
  "payments.title": "Payment",
  "orderDetail.title": "Order Detail",
  "offerDetail.title": "Offer Detail",
  "ptSlabDetail.title": "PT Slab Detail",

  // Sidebar Menu
  "sidebar.dashboard": "Dashboard",
  "sidebar.plans": "Plans",
  "sidebar.offers": "Offers",
  "sidebar.companies": "Companies",
  "sidebar.subscriptions": "Subscriptions",
  "sidebar.orders": "Orders",
  "sidebar.payments": "Payments",
  "sidebar.tenants": "Tenants",
  "sidebar.utility": "Utility",
  "sidebar.default-settings": "Default Settings",
  "sidebar.ptslab": "PT Slab",

  // Button's
  "add.tenant": "Add Tenant",
  "button.text.save": "Save",
  "button.text.cancel": "Cancel",
  "add.companies": "Add Company",
  "add.plan": "Add New Plan",

  // Form Text
  "form.text.registration": "Registration",

  // API Response Message
  "axios.api.success.message": "Saved Successfully",
  "axios.api.update.message": "Updated Successfully",
  "axios.api.deleted.message": "Deleted Successfully",
  "axios.api.clean.message": "Clean Successfully",

  // Company Registration form
  "form.registerCompany": "Company",
  "form.registerCompany.tittle": "Register your company here",
  "form.company.name": "Company Name",
  "form.companyCode": "Company Code",
  "form.registration.number": "Registration Number",
  "form.company.mobile": "Company Mobile",
  "form.company.email": "Company Email",
  "form.industry.type": "Industry Type",
  "form.employee.count.range": "Employee Count Range",
  "form.date.of.establishment": "Date Of Establishment",
  "form.address.line1": "Address Line1",
  "form.address.line2": "Address Line2",
  "form.city": "City",
  "form.state": "State",
  "form.country": "Country",
  "form.already.account": "Already have an account",
  "form.login.here": "Login here",
  "form.register.btn": "Register",
  "form.update.btn": "Update",
  "form.dateCreated": "Date Created",
  "form.dateLoginCreated": "Date Login Created",
  "form.dateModified": "Date Modified",

  // Subscription
  "update.plan": "Update Plan",
  "update.plan.planName": "Plan Name",
  "update.plan.planCode": "Plan Code",
  "update.plan.planType": "Plan Type",
  "update.plan.durationType": "Duration Type",
  "update.plan.month": "Months",
  "update.plan.days": "Days",
  "update.plan.maxEmployee": "Max Employee",
  "update.plan.unitRate": "Unit Rate",
  "update.plan.planPrice": "Plan Price",
  "update.plan.assetUnitRate": "Asset Unit Rate",
  "update.plan.projectUnitRate": "Project Unit Rate",
  "update.plan.documentUnitRate": "Document Unit Rate",
  "update.plan.maxAsset": "Max Asset",
  "update.plan.maxProject": "Max Project",
  "update.plan.maxDocument": "Max Document",
  "update.plan.planStatus": "Plan Status",
  "update.plan.comment": "Comment",
  "update.plan.modifiedBy": "Modified By",
  "update.plan.modifiedDate": "Modified Date",

  // Company Subscription
  "title.subscription": "Company Subscription",
  "add.subscription": "Add Subscription",

  // Order Details
  "title.orderDetail": "Order Detail",

  // Offer Details
  "offerDetail.add.button": "Add Offer",
  "offerDetail.add": "Add Offer Detail",
  "offerDetail.update": "Offer Detail",
  "text.offerDetail.offerName": "Offer Name",
  "text.offerDetail.offerDescription": "Offer Description",
  "text.offerDetail.offerMessage": "Offer Message",
  "text.offerDetail.planType": "Plan Type",
  "text.offerDetail.durationType": "Duration Type",
  "text.offerDetail.startDate": "Start Date",
  "text.offerDetail.endDate": "End Date",
  "text.offerDetail.discountPercent": "Discount %",
  "text.offerDetail.offerStatus": "Offer Status",

  // PT Slab Details
  "ptSlabDetail.add.button": "Add PT Slab",
  "ptSlabDetail.add": "Add PT Slab Detail",
  "ptSlabDetail.update": "PT Slab Detail",

  "text.ptSlabDetail.stateCode": "State Code",
  "text.ptSlabDetail.slabType": "Slab Type",
  "text.ptSlabDetail.tax": "Tax",
  "text.ptSlabDetail.from": "From",
  "text.ptSlabDetail.to": "To",
  "text.ptSlabDetail.gender": "Gender",
  "text.ptSlabDetail.month": "Month",
  "text.ptSlabDetail.interval": "Interval",
  "text.ptSlabDetail.status": "Status",

  // My Profile
  "title.myProfile.text": "My Profile",
  "button.text.changePassword": "Change Password",
  "message.changePassword.sendOtp.success": "OTP Sent on your Email",
  "forgotPassword.heading": "Forgot Password",
  "forgotPassword.button": "Send OTP to Mail",
  "button.text.updatePassword": "Update Password",
  "forget.password.newPassword": "New Password",
  "forget.password.confirmPassword": "Confirm Password",
  "button.text.passwordChangedSuccess.success": "Password Changed Successfully",

  // Utility
  "button.utility.cleanMasterDB": "Clean Master DB Change Logs",
  "button.utility.cleanTenantDB": "Clean Tenant DB Change Logs",
  "text.utility.updateSchema": "Update Schema",
  "text.utility.selectSchema": "Select Schema",
  "button.utility.updateSchema": "Update Schema",
  "button.utility.selectSchema": "Select Schema",

  // Dashboard Card
  "text.dashboard.adminDetails": "Admin Details",
  "text.dashboard.totalCompanies": "Total Companies",
  "text.dashboard.totalSubscriptionPlans": "Total Subscription Plans",
  "text.dashboard.totalCompanySubscription": "Total Company Subscription",
  "text.dashboard.totalTenants": "Total Tenants",

  // Payments
  "title.payment": "Payments",
  "text.payment.detail": "Payment Details",

  //
  "default.setting.title": "Default Settings",
  "error.message.updateAllSchema": "Please enter valid SQL query",
  "text.message.updateSqlExample": " Example:- Update __schema.tableName set columnName='testValue' where id = 'id'",
  "text.message.selectSqlExample": " Example:- SELECT model_number, serial_number, asset_type , COUNT(*) FROM __schema.asset GROUP BY model_number, serial_number, asset_type HAVING COUNT(*) > 1;",

  // Error Messages
  "subscription.plan.not.found": "Subscription plan not found",
  "all.active.subscription-plan.fetched.successfully": "All active subscription plan fetched successfully",
  "email.not.verified": "Email not verified",
  "company.entity.not.found": "Company not found",
  "offer.detail.entity.not.found": "Offer detail not found",
  "subscription.entity.not.found": "Subscription not found",
  "ctc.setting.entity.not.found": "CTC setting not found",
  "order.entity.not.found": "Order not found",
  "payment.entity.not.found": "Payment not found",
  "plan.entity.not.found": "Plan not found",
  "entity.subscription.plan.already.exists!": "Subscription plan already exists!",
  "entity.username.cannot.be.empty!": "Username cannot be empty!",
  "entity.otp.cannot.be.empty!": "Otp cannot be empty!",
  "entity.new.password.cannot.be.empty!": "New password cannot be empty!",
  "entity.company subscription.already.exists!": "Company subscription already exists!",
  "entity.offer.detail.already.exists!": "Offer detail already exists!",
  "entity.please.select.valid.plan!": "Please select valid plan!",
  "user.creation.failed": "User creation failed",
  "user.list": "User list",
  "invalid.auth.token": "Invalid auth token",
  "invalid.company.detail": "Invalid company detail",
  "user.update.failed": "User update failed",
  "invalid.user.id": "Invalid user Id",
  "user.not.found": "User not found",
  "login.failed": "Login failed",
  "invalid.credentials": "Invalid credentials",
  "exception.occurred.while.deleting.user": "Exception occurred while deleting user",
  "user.not.found.with.username: ": "User not found with username",
  "invalid.employee.detail": "Invalid employee detail",
  "invalid.email.provided": "Invalid email provided",
  "subscription.plan.expired": "Subscription plan expired",
  "subscription.plan.limit.exceeded": "Subscription plan limit exceeded",
  "user.role.update.failed": "User role update failed",
  "password.policy": "Password policy",
  "company.already.exists.with.given.email": "Company already exists with given email",
  "subscription.expiring": "Subscription expiring",
  "invalid.plan.to.be.upgraded": "Invalid plan to be upgraded",
  "next.subscription.not.found": "Next subscription not found",
  "cannot.delete.subscription": "Cannot deleted subscription",
  "next.subscription.already.exists": "Next subscription already exists",
  "invalid.current.password": "Invalid current password",
  "logout.error": "Logout error",
  "error.in.signup": "Error in signup",
  "invalid.expired.otp": "Invalid expired otp",
  "max.two.roles.can.be.assigned": "Max two roles can be assigned",
  "error.in.otp.deletion": "Error in otp deletion",
  "user.with.role.exists": "User role exists",
  "password.should.not.be.same.as.old": "Password should not be same as old",
  "error.occurred.during.password.update": "Error occurred during password update",
  "order.not.found.with": "Order not found with",
  "unable.to.get.payslip": "Unable to get payslip",
  "something.went.wrong.cannot.create .his.user.now": "Something went wrong cannot create his user now",
  "unable.to.create.order.at.the.moment.please.try.after.some.time": "Unable to create order at the moment please try after some time",

  // Delete success Messages
  "offer.detail.delete.success": "Offer detail delete successfully",
  "entity.deleted.successfully": "Deleted successfully",
  "company.detail.deleted.successfully": "Company detail deleted successfully",
  "company.subscription.delete.success": "Company subscription deleted successfully",
  "subscription.plan.delete.success": "Subscription plan deleted successfully",
  "user.deleted": "User deleted successfully",

  // Success Messages
  "mail.sent.success": "Mail sent successfully",
  "terms.and.condition": "Terms and condition",
  "offer.detail": "Offer detail",
  "company.detail.saved.successfully": "Company detail saved successfully",
  "user.roles.successfully.updated": "User roles updated successfully",
  "company.detail.updated.successfully": "Company detail updated successfully",
  "terms.and.condition.updated.successfully": "Terms and condition updated successfully",
  "terms.and.condition.saved.successfully": "Terms and condition saved successfully",
  "records.fetched.successfully": "Records fetched successfully",
  "sign.in.successfully": "Sign in successfully",
  "sign.out.successfully": "Sign out successfully",
  "sign-out.error": "Sign out error",
  "records.update.successfully": "Records update successfully",
  "records.saved.successfully": "Records saved successfully",
  "you.do.not.have.access.this.url": "You do not have access this url",
  "password.updated": "Password updated successfully",
  "otp.sent.": "Otp sent successfully",
  "otp.for.email.verification": "Otp for email verification",
  "user.created": "User created successfully",
  "unauthorized.to.create.user.with.given.role": "Unauthorized to create user with given role",
  "default.settings.update.success": "Default settings updated success",
  "offer.detail.updated.successfully": "Offer detail updated successfully",
  "plan.detail.updated.successfully": "Plan detail updated successfully",
};
