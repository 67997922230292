import React from "react";
import errorImage from "../../assets/images/errorImage.jpg";
import { Button } from "reactstrap";

const PageNotFound = () => {
  const onButtonClick = () => {
    window.location.replace("/");
  };

  return (
    <>
      <div className="pageNotFound">
        <img
          className="img-margin"
          src={errorImage}
          height={150}
          width={300}
          alt="404 Image"
        />
        <h1 style={{ marginTop: "2rem" }}>Page Not Found</h1>
        <h4 style={{ textAlign: "center" }}>Sorry, This page is not available</h4>
        <Button
          style={{ marginTop: "1rem", borderRadius: "15px" }}
          color="primary"
          className="addDetailsBtn"
          onClick={onButtonClick}>
          {"Go To Home"}
        </Button>
      </div>
    </>
  );
};

export default PageNotFound;
