import React, { useState } from "react";
import AddCompanyDetails from "./AddCompanyDetails";
import CompanyDetails from "./CompanyDetails";

const CompanyWrapper = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [reloadDataList, setReloadDataList] = useState(false);

  return (
    <>
      <CompanyDetails
        reloadDataList={reloadDataList}
        setReloadDataList={setReloadDataList}
        setIsOpen={setIsOpen}
      />
      {isOpen && (
        <AddCompanyDetails
          setReloadDataList={setReloadDataList}
          openDialog={isOpen}
          setOpenDialog={setIsOpen}
        />
      )}
    </>
  );
};

export default CompanyWrapper;
