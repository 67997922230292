// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-section.companyRegistration {
  max-width: 800px;
}
.login-6 .companyRegistration .login-inner-form .mb-3 .form-control {
  padding: 8px 15px;
  border: 1px solid #aaa;
  border-radius: 4px;
}
.login-6 .companyRegistration .form-label {
  font-size: 15px;
  margin-bottom: 5px;
}
.renderStatusIconGreen {
  color: rgb(7, 188, 12);
}
.renderStatusIconRed {
  color: #ff0000;
}
`, "",{"version":3,"sources":["webpack://./src/components/company-details/companyRegister.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,sBAAsB;AACxB;AACA;EACE,cAAc;AAChB","sourcesContent":[".form-section.companyRegistration {\n  max-width: 800px;\n}\n.login-6 .companyRegistration .login-inner-form .mb-3 .form-control {\n  padding: 8px 15px;\n  border: 1px solid #aaa;\n  border-radius: 4px;\n}\n.login-6 .companyRegistration .form-label {\n  font-size: 15px;\n  margin-bottom: 5px;\n}\n.renderStatusIconGreen {\n  color: rgb(7, 188, 12);\n}\n.renderStatusIconRed {\n  color: #ff0000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
