import { Checkbox } from "@mui/material";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { CgClose } from "react-icons/cg";
import { toast } from "react-toastify";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import * as yup from "yup";
import { companyDetailsApi } from "../../apilist/ApiLists";
import AppColor from "../../components/shared/appColor/AppColors";
import { AxiosInstance } from "../../config/AxiosConfig";
import { dateTimeFormatter } from "../../util/Helper";
import IntlMessages from "../../util/IntlMessages";
import ModelSelfFooter from "../shared/Model/ModelSelfFooter";

const AddCompanyDetails = ({ openDialog, setOpenDialog, companyData, reloadFunction, setReloadDataList }) => {
  AddCompanyDetails.propTypes = {
    openDialog: PropTypes.bool,
    setOpenDialog: PropTypes.func,
    companyData: PropTypes.object,
    reloadFunction: PropTypes.func,
    setReloadDataList: PropTypes.func,
  };

  const compnayDataById = companyData?.id;

  let validationSchema = yup.object().shape({
    companyName: yup.string().required(),
    registrationNumber: yup.string().required(),
    companyMobile: yup.string().required(),
    companyEmail: yup.string().required(),
    industryType: yup.string().required(),
    employeeCountRange: yup.string().required(),
    dateOfEstablishment: yup.string().required(),
    addressLine1: yup.string().required(),
    addressLine2: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
    country: yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      companyName: "",
      companyCode: "",
      registrationNumber: "",
      companyMobile: "",
      companyEmail: "",
      industryType: "",
      employeeCountRange: 0,
      dateOfEstablishment: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      country: "",
      dateCreated: "",
      dateLoginCreated: "",
      dateModified: "",
      loginCreated: "",
      active: "",
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema: validationSchema,
  });

  useEffect(() => {
    if (companyData) {
      formik.setValues(companyData);
    }
  }, [companyData]);

  const renderErrorMessage = (field) => {
    return formik.touched[field] && <div className="text-danger">{formik.errors[field]}</div>;
  };

  const CreateCompanyDetails = async (values) => {
    await AxiosInstance.post(`/${companyDetailsApi}`, values).then((response) => {
      setReloadDataList((prev) => !prev);
      setOpenDialog(false);
    });
  };

  const UpdateCompanyDetails = async (values) => {
    await AxiosInstance.put(`/${companyDetailsApi}/${compnayDataById}`, values).then((response) => {
      setOpenDialog(false);
      reloadFunction && reloadFunction(0, 10, "", "axios.api.update.message");
    });
  };

  const handleSubmit = async (values) => {
    if (compnayDataById) {
      UpdateCompanyDetails(values);
    } else {
      CreateCompanyDetails(values);
    }
  };

  if (formik.isSubmitting && formik.isValidating && !formik.isValid) {
    toast.error("Please fill all the mandatory fields", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }

  return (
    <>
      <ModelSelfFooter
        open={openDialog}
        heading={<IntlMessages id="form.registerCompany" />}
        closePopUp={
          <CgClose
            onClick={() => setOpenDialog(false)}
            className="popUpCloseIcon"
          />
        }
        closeFn={() => setOpenDialog(false)}
        maxWidth="xs"
        footer={<span className="DisplayNone">.</span>}>
        <Form
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          className="mdForm"
          onSubmit={formik.handleSubmit}>
          <Row>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="companyName"
                  className="requiredField">
                  <IntlMessages id={`form.company.name`} />
                </Label>
                <Input
                  type="text"
                  id="companyName"
                  name="companyName"
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.companyName && !formik.values.companyName}
                  onBlur={formik.handleBlur}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="companyCode"
                  className="requiredField">
                  <IntlMessages id={`form.companyCode`} />
                </Label>
                <Input
                  type="text"
                  id="companyCode"
                  name="companyCode"
                  value={formik.values.companyCode}
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.companyCode && !formik.values.companyCode}
                  onBlur={formik.handleBlur}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="registrationNumber"
                  className="requiredField">
                  <IntlMessages id={`form.registration.number`} />
                </Label>
                <Input
                  type="text"
                  id="registrationNumber"
                  name="registrationNumber"
                  value={formik.values.registrationNumber}
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.registrationNumber && !formik.values.registrationNumber}
                  onBlur={formik.handleBlur}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="schema"
                  className="requiredField">
                  <IntlMessages id={`form.company.email`} />
                </Label>
                <Input
                  type="text"
                  id="companyEmail"
                  name="companyEmail"
                  value={formik.values.companyEmail}
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.companyEmail && !formik.values.companyEmail}
                  onBlur={formik.handleBlur}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="companyMobile"
                  className="requiredField">
                  <IntlMessages id={`form.company.mobile`} />
                </Label>
                <Input
                  type="text"
                  id="companyMobile"
                  name="companyMobile"
                  value={formik.values.companyMobile}
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.companyMobile && !formik.values.companyMobile}
                  onBlur={formik.handleBlur}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="enabled"
                  className="requiredField">
                  <IntlMessages id={`form.industry.type`} />
                </Label>
                <Input
                  type="text"
                  id="industryType"
                  name="industryType"
                  value={formik.values.industryType}
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.industryType && !formik.values.industryType}
                  onBlur={formik.handleBlur}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="enabled"
                  className="requiredField">
                  <IntlMessages id={`form.employee.count.range`} />
                </Label>
                <Input
                  type="text"
                  id="employeeCountRange"
                  name="employeeCountRange"
                  value={formik.values.employeeCountRange}
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.employeeCountRange && !formik.values.employeeCountRange}
                  onBlur={formik.handleBlur}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="enabled"
                  className="requiredField">
                  <IntlMessages id={`form.date.of.establishment`} />
                </Label>
                <Input
                  type="date"
                  id="dateOfEstablishment"
                  name="dateOfEstablishment"
                  value={formik.values.dateOfEstablishment}
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.dateOfEstablishment && !formik.values.dateOfEstablishment}
                  onBlur={formik.handleBlur}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="dateCreated">
                  <IntlMessages id={`form.dateCreated`} />
                </Label>
                <Input
                  type="text"
                  id="dateCreated"
                  name="dateCreated"
                  value={formik?.values?.dateCreated ? dateTimeFormatter(formik?.values?.dateCreated) : ""}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor="dateLoginCreated">
                  <IntlMessages id={`form.dateLoginCreated`} />
                </Label>
                <Input
                  type="text"
                  id="dateLoginCreated"
                  name="dateLoginCreated"
                  value={formik?.values?.dateLoginCreated ? dateTimeFormatter(formik?.values?.dateLoginCreated) : ""}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="dateModified">
                  <IntlMessages id={`form.dateModified`} />
                </Label>
                <Input
                  type="text"
                  id="dateModified"
                  name="dateModified"
                  value={formik?.values?.dateModified ? dateTimeFormatter(formik?.values?.dateModified) : ""}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="enabled"
                  className="requiredField">
                  <IntlMessages id={`form.address.line1`} />
                </Label>
                <Input
                  type="text"
                  id="addressLine1"
                  name="addressLine1"
                  value={formik.values.addressLine1}
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.addressLine1 && !formik.values.addressLine1}
                  onBlur={formik.handleBlur}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="enabled"
                  className="requiredField">
                  <IntlMessages id={`form.address.line2`} />
                </Label>
                <Input
                  type="text"
                  id="addressLine2"
                  name="addressLine2"
                  value={formik.values.addressLine2}
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.addressLine2 && !formik.values.addressLine2}
                  onBlur={formik.handleBlur}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="enabled"
                  className="requiredField">
                  <IntlMessages id={`form.city`} />
                </Label>
                <Input
                  type="text"
                  id="city"
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.city && !formik.values.city}
                  onBlur={formik.handleBlur}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="enabled"
                  className="requiredField">
                  <IntlMessages id={`form.state`} />
                </Label>
                <Input
                  type="text"
                  id="state"
                  name="state"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.state && !formik.values.state}
                  onBlur={formik.handleBlur}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="enabled"
                  className="requiredField">
                  <IntlMessages id={`form.country`} />
                </Label>
                <Input
                  type="text"
                  id="country"
                  name="country"
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.country && !formik.values.country}
                  onBlur={formik.handleBlur}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label htmlFor="active">Active Status</Label>
              <Checkbox
                checked={formik?.values?.active}
                name="active"
                onChange={formik.handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Col>
            <Col>
              <Label htmlFor="loginCreated">Login Created</Label>
              <Checkbox
                checked={formik?.values?.loginCreated}
                name="loginCreated"
                onChange={formik.handleChange}
                inputProps={{ "aria-label": "controlled" }}
                disabled
              />
            </Col>
          </Row>
          <Row className="FooterForm">
            <Button
              variant="contained"
              color="outline-secondary"
              onClick={() => setOpenDialog(false)}
              className="buttonSpacing">
              <IntlMessages id={`button.text.cancel`} />
            </Button>
            <Button
              style={{ backgroundImage: `${AppColor.BackgroundImage}` }}
              variant="contained"
              type="submit"
              className="buttonSpacing">
              <IntlMessages id={`form.update.btn`} />
            </Button>
          </Row>
        </Form>
      </ModelSelfFooter>
    </>
  );
};
export default AddCompanyDetails;
