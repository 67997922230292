import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "reactstrap";
import IntlMessages from "../../util/IntlMessages";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  lineHeight: "60px",
  borderRadius: "8px",
}));

const lightTheme = createTheme({ palette: { mode: "light" } });

const DefaultSettingCard = ({ setIsOpen }) => {
  DefaultSettingCard.propTypes = {
    setIsOpen: PropTypes.func.isRequired,
  };
  const handlePopup = () => {
    setIsOpen(true);
  };

  return (
    <div>
      {[lightTheme].map((theme, index) => (
        <Grid
          item
          key={index}>
          <ThemeProvider theme={theme}>
            <Row>
              <Col md={4}>
                <Box
                  onClick={() => handlePopup()}
                  className="cardBoxes">
                  {[12].map((elevation) => (
                    <Item
                      key={elevation}
                      elevation={elevation}>
                      <span className="default-font-size">
                        <IntlMessages id="default.setting.title" />
                      </span>
                    </Item>
                  ))}
                </Box>
              </Col>
            </Row>
          </ThemeProvider>
        </Grid>
      ))}
    </div>
  );
};
export default DefaultSettingCard;
