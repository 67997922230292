import { Checkbox } from "@mui/material";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { CgClose } from "react-icons/cg";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { defaultSettingApi } from "../../apilist/ApiLists";
import AppColor from "../../components/shared/appColor/AppColors";
import { AxiosInstance } from "../../config/AxiosConfig";
import IntlMessages from "../../util/IntlMessages";
import ModelSelfFooter from "../shared/Model/ModelSelfFooter";

const AddUpdateDefaultSetting = ({ openDialog, setOpenDialog }) => {
  AddUpdateDefaultSetting.propTypes = {
    openDialog: PropTypes.bool,
    setOpenDialog: PropTypes.func,
  };
  const formik = useFormik({
    initialValues: {
      id: "",
      variablePercent: 0,
      basicPercent: 0,
      hraPercent: 0,
      ltaPercent: 0,
      deductPfFlag: true,
      eePfPercent: 0,
      erPfPartOfCtc: true,
      erPfPercent: 0,
      deductEsiFlag: true,
      eeEsiPercent: 0,
      deductPtFlag: true,
      erEsiPartOfCtc: true,
      erEsiPercent: 0,
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const [defaultSettings, setDefaultSettings] = useState(formik.values);

  const getDefaultSetting = async () => {
    await AxiosInstance.get(`${defaultSettingApi}`).then((result) => {
      setDefaultSettings(result);
    });
  };

  const handleSubmit = async (values) => {
    await AxiosInstance.put(`/${defaultSettingApi}`, values).then((response) => {
      setOpenDialog(false);
    });
  };

  useEffect(() => {
    formik.setValues(defaultSettings);
  }, [defaultSettings]);

  useEffect(() => {
    getDefaultSetting();
  }, []);

  return (
    <>
      <ModelSelfFooter
        open={openDialog}
        heading={<IntlMessages id="default.setting.title" />}
        closePopUp={
          <CgClose
            onClick={() => setOpenDialog(false)}
            className="popUpCloseIcon"
          />
        }
        closeFn={() => setOpenDialog(false)}
        maxWidth="xs"
        footer={<span className="DisplayNone">.</span>}>
        <div>
          <Form
            onKeyPress={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
            className="mdForm"
            onSubmit={formik.handleSubmit}>
            <div className="default-setting-popup">
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor="variablePercent">Variable Percent</Label>
                    <Input
                      type="number"
                      id="variablePercent"
                      name="variablePercent"
                      value={formik.values.variablePercent}
                      onChange={formik.handleChange}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor="basicPercent">Basic Percent</Label>
                    <Input
                      type="number"
                      id="basicPercent"
                      name="basicPercent"
                      value={formik.values.basicPercent}
                      onChange={formik.handleChange}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor="hraPercent">HRA Percent</Label>
                    <Input
                      type="number"
                      id="hraPercent"
                      name="hraPercent"
                      value={formik.values.hraPercent}
                      onChange={formik.handleChange}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor="ltaPercent">LTA Percent</Label>
                    <Input
                      type="number"
                      id="ltaPercent"
                      name="ltaPercent"
                      value={formik.values.ltaPercent}
                      onChange={formik.handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Employee PF Percent</Label>
                    <Input
                      type="number"
                      id="eePfPercent"
                      name="eePfPercent"
                      value={formik.values.eePfPercent}
                      onChange={formik.handleChange}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Employer PF Percent</Label>
                    <Input
                      type="number"
                      id="erPfPercent"
                      name="erPfPercent"
                      value={formik.values.erPfPercent}
                      onChange={formik.handleChange}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Employee ESI Percent</Label>
                    <Input
                      type="number"
                      id="eeEsiPercent"
                      name="eeEsiPercent"
                      value={formik.values.eeEsiPercent}
                      onChange={formik.handleChange}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label htmlFor="erEsiPercent">Employer ESI Percent</Label>
                    <Input
                      type="number"
                      id="erEsiPercent"
                      name="erEsiPercent"
                      value={formik.values.erEsiPercent}
                      onChange={formik.handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className="default-setting-popup">
              <Row>
                <Col>
                  <Label>Deduct ESI Flag</Label>
                  <Checkbox
                    checked={formik?.values?.deductEsiFlag}
                    name="deductEsiFlag"
                    onChange={formik.handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Col>
                <Col>
                  <Label>Deduct PF Flag</Label>
                  <Checkbox
                    checked={formik?.values?.deductPfFlag}
                    name="deductPfFlag"
                    onChange={formik.handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Col>
                <Col>
                  <Label>Deduct PT Flag</Label>
                  <Checkbox
                    checked={formik?.values?.deductPtFlag}
                    name="deductPtFlag"
                    onChange={formik.handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <Label>Employer ESI Part Of CTC</Label>
                  <Checkbox
                    checked={formik?.values?.erEsiPartOfCtc}
                    name="erEsiPartOfCtc"
                    onChange={formik.handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Col>
                <Col>
                  <Label>Employer PF Part Of CTC</Label>
                  <Checkbox
                    checked={formik?.values?.erPfPartOfCtc}
                    name="erPfPartOfCtc"
                    onChange={formik.handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Col>
              </Row>
            </div>
            <Row className="FooterForm">
              <Button
                variant="contained"
                color="outline-secondary"
                onClick={() => setOpenDialog(false)}
                className="buttonSpacing">
                <IntlMessages id={`button.text.cancel`} />
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                style={{ backgroundImage: `${AppColor.BackgroundImage}` }}
                className="buttonSpacing">
                <IntlMessages id={`button.text.save`} />
              </Button>
            </Row>
          </Form>
        </div>
      </ModelSelfFooter>
    </>
  );
};
export default AddUpdateDefaultSetting;
