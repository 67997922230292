import { Avatar, Box, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from "@mui/material";
import React, { useState } from "react";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockResetIcon from "@mui/icons-material/LockReset";
import * as AiIcons from "react-icons/ai";
import { FaBars } from "react-icons/fa";
import hrmsHostLogo250by60 from "../../../assets/images/hrmshost-logo-250x60.png";
import { NavBarDesign } from "../../../constants/StyleNavBar";
import { Logout } from "@mui/icons-material";
import LoggedInUser from "../../myAccount/LoggedInUser";
import ChangePassword from "../../authentication/ChangePassword";
import ForgetPassword from "../../authentication/ForgetPassword";
import { logoutApi } from "../../../apilist/ApiLists";
import { AxiosInstance } from "../../../config/AxiosConfig";

const Header = ({ toggleSideBar, isOpen, breakPoint, ResponsiveWdth }) => {
  const userDetails = sessionStorage.getItem("userdetails");
  const [openDialog, setOpenDialog] = useState(false);
  const [openForgotPassword, setForgotPassword] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loggedInUserInfo, setLoggedInUserInfo] = useState();
  const [openChangePassword, setChangePassword] = useState(false);

  const profile = JSON.parse(sessionStorage.getItem("profile"));
  const userName = profile.username;

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const lotteryStyle = {
    borderRadius: "10px",
    width: "32px",
    height: "32px",
    textAlign: "center",
    marginRight: "10px",
  };
  const BigImage = {
    borderRadius: "10px",
    width: "50px",
    height: "50px",
    textAlign: "center",
    marginRight: "10px",
  };
  const modalStyle = {
    top: "60px",
    right: "100px",
    bottom: "0",
    left: "0",
    zIndex: "10040",
    overflow: "auto",
    overflowY: "auto",
    height: "auto",
    fontSize: "15px",
  };

  const logout = async (token) => {
    await AxiosInstance.post(`${logoutApi}?username=${encodeURIComponent(userName)}`).then((response) => {});
    sessionStorage.clear(token);
    window.location.href = "/login";
  };

  const onMyProfileClick = () => {
    setOpenDialog(true);
    handleClose();
  };
  const onChangePasswordClick = () => {
    setChangePassword(true);
    handleClose();
  };

  return (
    <div className="NavBarMenu">
      <NavBarDesign>
        {ResponsiveWdth < breakPoint &&
          (() => {
            if (isOpen == true) {
              return (
                <div
                  className="barsMobile closeIcon"
                  onClick={toggleSideBar}>
                  <AiIcons.AiOutlineClose />
                </div>
              );
            } else if (isOpen == false) {
              return (
                <div
                  className="barsMobile closeIcon"
                  onClick={toggleSideBar}>
                  <FaBars />
                </div>
              );
            }
          })()}
        {ResponsiveWdth > breakPoint && (
          <div className="toggleFullWidth">
            <div
              className="barsMobile"
              onClick={toggleSideBar}>
              <FaBars />
            </div>
          </div>
        )}

        <div className="brandLogo">
          <img
            src={hrmsHostLogo250by60}
            alt=""
            onerror="this.style.display = 'none';"
            width="200px"
          />
        </div>
        <React.Fragment>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              top: "60px",
            }}>
            <Tooltip title="Account settings">
              <IconButton
                className=""
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}>
                <Avatar
                  className="bgColor"
                  onClick={handleShow}
                  sx={{ width: 32, height: 32 }}>
                  {profile.firstName.substring(0, 1)}
                </Avatar>
              </IconButton>
            </Tooltip>
          </Box>

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            className="myProfilePopup">
            <MenuItem className="position">
              <Avatar className="bgColor">{profile.firstName.substring(0, 1)}</Avatar>
            </MenuItem>
            <div className="position">{profile.firstName + " " + profile.lastName}</div>
            <Divider />
            <MenuItem onClick={onMyProfileClick}>
              <ListItemIcon>
                <AssignmentIndIcon />
              </ListItemIcon>
              My Profile
            </MenuItem>
            <MenuItem>
              <ListItemIcon>
                <MailOutlineIcon />
              </ListItemIcon>
              {profile.email}
            </MenuItem>
            <MenuItem onClick={onChangePasswordClick}>
              <ListItemIcon>
                <LockResetIcon />
              </ListItemIcon>
              Change Password
            </MenuItem>
            <MenuItem onClick={logout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </React.Fragment>
      </NavBarDesign>
      {openDialog && (
        <LoggedInUser
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          loggedInUserInfo={loggedInUserInfo}
        />
      )}
      {openForgotPassword && (
        <ForgetPassword
          openForgotPassword={openForgotPassword}
          setForgotPassword={setForgotPassword}
        />
      )}
      {openChangePassword && (
        <ChangePassword
          openChangePassword={openChangePassword}
          setChangePassword={setChangePassword}
        />
      )}
    </div>
  );
};
export default Header;
