import React, { useState } from "react";
import AddOfferDetail from "./AddOfferDetail";
import OfferDetailList from "./OfferDetailList";

const OfferDetailWrapper = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [reloadDataList, setReloadDataList] = useState(false);

  return (
    <>
      <OfferDetailList
        reloadDataList={reloadDataList}
        setReloadDataList={setReloadDataList}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />
      {isOpen && (
        <AddOfferDetail
          setReloadDataList={setReloadDataList}
          openDialog={isOpen}
          setOpenDialog={setIsOpen}
        />
      )}
    </>
  );
};

export default OfferDetailWrapper;
