import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { Col, Row } from "reactstrap";
import { professionalTaxApi } from "../../apilist/ApiLists";
import { AxiosInstance } from "../../config/AxiosConfig";

import PropTypes from "prop-types";
import AppColor from "../../components/shared/appColor/AppColors";
import DataTableWrapper from "../../tables/DataTableWrapper";
import SearchBox from "../../tables/SearchBox";
import { dateFormatter } from "../../util/Helper";
import IntlMessages from "../../util/IntlMessages";
import DeleteButton from "../shared/DeleteButton";
import TitleHeader from "../shared/pageTitle/TitleHeader";
import AddPtSlab from "./AddPtSlab";

const ActionButton = ({ rowData, reloadFunction }) => {
  ActionButton.propTypes = {
    rowData: PropTypes.object,
    reloadFunction: PropTypes.func,
  };

  const [openDialog, setOpenDialog] = useState(false);
  return (
    <span style={{ textAlign: "center" }}>
      <FiEdit
        style={{ cursor: "pointer", color: AppColor.Pink }}
        onClick={() => setOpenDialog(true)}
      />
      {openDialog && (
        <AddPtSlab
          ptSlabData={rowData}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          reloadFunction={reloadFunction}
        />
      )}
    </span>
  );
};

const PtSlabs = ({ setIsOpen, reloadDataList, setReloadDataList }) => {
  PtSlabs.propTypes = {
    setIsOpen: PropTypes.func,
    reloadDataList: PropTypes.bool,
    setReloadDataList: PropTypes.func,
  };
  const API = {
    delete: `${professionalTaxApi}`,
  };

  const [ptSlabDetail, setPtSlabDetail] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [statusCode, setStatusCode] = useState("");
  const [searchKey, setSearchKey] = useState("");

  ptSlabDetail.map((offer) => {
    offer.StartDate = dateFormatter(offer.startDate);
    offer.EndDate = dateFormatter(offer.endDate);
  });

  const ptSlabDetailData = async (page, rowsPerPage, search = "") => {
    setLoading(true);
    await AxiosInstance.get(`/${professionalTaxApi}?pageNo=${page}&pageSize=${rowsPerPage}&search=${search}`)
      .then((result) => {
        [...result.resultList[0]].map((row, index) => (row.sno = rowsPerPage * page + (index + 1)));
        setPtSlabDetail(result.resultList[0]);
        setTotal(result.numberOfItems);
        setLoading(false);
      })
      .catch((error) => {
        setStatusCode(error?.errorCode);
      });
    setLoading(false);
  };
  const columns = [
    { label: "State", accessor: "stateCode" },
    { label: "Type", accessor: "slabType" },
    { label: "Tax", accessor: "tax" },
    { label: "To Amount", accessor: "to" },
    { label: "From Amount", accessor: "from" },
    { label: "Gender", accessor: "gender" },
    { label: " Month", accessor: "month" },
    { label: "Interval", accessor: "interval" },
    {
      label: "Action",
      accessor: "custom",
      type: "actionButtonWithUpdate",
      component: ({ rowData }) => {
        return (
          <>
            <Row>
              <Col>
                <ActionButton
                  rowData={rowData}
                  reloadFunction={ptSlabDetailData}
                />
              </Col>
              <Col>
                <DeleteButton
                  api={API}
                  Id={rowData.id}
                  refreshList={ptSlabDetailData}
                  message={"PT Slab Detail"}
                />
              </Col>
            </Row>
          </>
        );
      },
    },
  ];

  const handlePopup = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    if (reloadDataList) {
      setReloadDataList(true);
    }
  }, [reloadDataList]);

  return (
    <>
      <TitleHeader
        shouldShowArrow={false}
        title="HRMS-Admin"
        pageTitle={"ptSlabDetail.title"}
      />
      <SearchBox
        addLink={true}
        actionName={<IntlMessages id={"ptSlabDetail.add.button"} />}
        handleAddBtnClick={handlePopup}
        setSearchKey={setSearchKey}
      />
      <DataTableWrapper
        data={ptSlabDetail}
        columns={columns}
        count={total}
        updateData={ptSlabDetailData}
        loading={loading}
        statusCode={statusCode}
        searchStr={searchKey}
      />
    </>
  );
};

export default PtSlabs;
