import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import RoutesList from "../config/Routes";
import SideBar from "./shared/sidebar/SideBar";

const Landingpage = ({ setPageError }) => {
  const location = useLocation();
  let newpath = location.pathname;
  let pathIncID;
  if (newpath.split("/").length === 3) {
    pathIncID = newpath.split("/")[2].includes("-");
  } else if (newpath.split("/").length === 4) {
    pathIncID = newpath.split("/")[3].includes("-");
  }

  const routeExist = RoutesList.find((item) => {
    if (!pathIncID) {
      return newpath === item.path;
    } else {
      return newpath.split("/")[1] === item.path.split("/")[1];
    }
  });

  if (!routeExist) {
    setPageError(true);
  }
  let token = sessionStorage.getItem("token");
  if (!token || token.length <= 0) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <SideBar>
        <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
        {RoutesList.map((route, i) => {
          return <Route key={`route-${i}`} exact={route?.exact} path={`${route.path}`} component={route.component} />;
        })}
      </SideBar>
    </>
  );
};

export default Landingpage;
