import React from "react";
import { dateFormatter } from "../util/Helper";

const TableBody = ({ tableData, columns, statusCode }) => {
  if (tableData.length === 0) {
    return (
      <tbody className="NoDataFound">
        <tr>{statusCode == (500 || 401 || 402 || 403) ? <td colSpan={columns.length}></td> : <td colSpan={columns.length}>No Data Found</td>}</tr>
      </tbody>
    );
  } else {
    return (
      <tbody>
        {tableData.map((data, index) => {
          return (
            <tr key={index}>
              {columns.map((col) => {
                if (col?.type && col.type == "custom") {
                  return (
                    <td key={col.accessor}>
                      <col.component rowData={data} />
                    </td>
                  );
                }

                if (col?.type && col.type == "actionButton") {
                  return (
                    <td key={col.accessor}>
                      <col.component rowData={data} reloadFunction={col.reloadFunction} />
                    </td>
                  );
                }
                if (col?.type && col.type == "actionButtonWithUpdate") {
                  return (
                    <td key={col.accessor} className="actionUpdate">
                      <col.component rowData={data} afterUpdateFn={col.afterActionFn} />
                    </td>
                  );
                }
                if (col?.type && col.type == "currency") {
                  return (
                    <td className="textbold" key={col.accessor}>
                      {data[col.accessor]?.toLocaleString("en-IN", {
                        style: "currency",
                        currency: "INR",
                      })}
                    </td>
                  );
                }
                if (col?.type && col.type == "currency_Paisa") {
                  return (
                    <td className="textbold" key={col.accessor}>
                      {(data[col.accessor] / 100).toLocaleString("en-IN", {
                        style: "currency",
                        currency: "INR",
                      })}
                    </td>
                  );
                }
                if (col?.type && col.type == "dateFun") {
                  return <td key={col.accessor}>{data[col.accessor] ? dateFormatter(data[col.accessor]) : "--"}</td>;
                }
                if (col?.type && col.type == "number") {
                  return <td key={col.accessor}>{data[col.accessor]}</td>;
                }
                if (col?.type && col.type == "num") {
                  return <td key={col.accessor}>{data[col.accessor] / 100}</td>;
                }
                const tData = data[col.accessor] ? data[col.accessor] : "--";
                return <td key={col.accessor}>{tData}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    );
  }
};

export default TableBody;
