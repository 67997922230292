import React from "react";
import AppUserDetails from "../components/app-user-details/AppUserDetails";
import Login from "../components/authentication/Login";
import SignUp from "../components/authentication/SignUp";
import CompanyWrapper from "../components/company-details";
import CompanyRegistration from "../components/company-details/CompanyRegistration";
import CompanySubscriptionWrapper from "../components/company-subscriptions";
import Home from "../components/dashboard/Home";
import DefaultSettingWrapper from "../components/default-setting";
import OfferDetailWrapper from "../components/offer-details";
import OrderDetailWrapper from "../components/order-details";
import PaymentsWrapper from "../components/payments";
import TenantWrapper from "../components/tenants";
import UtilityWrapper from "../components/utility";
import PlanWrapper from "../components/plans/PlanWrapper";
import professinalTax from "../components/ptSlabs";

const NoF = () => {
  return <p> No component found</p>;
};

const RoutesList = [
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/signup",
    component: SignUp,
  },
  {
    path: "/",
    component: Home,
    exact: true,
  },
  {
    path: "/dashboard",
    component: Home,
    exact: true,
  },
  {
    path: "/companies",
    component: CompanyWrapper,
    exact: true,
  },
  {
    path: "/company-registration",
    component: CompanyRegistration,
    exact: true,
  },
  {
    path: "/ptslabs",
    component: professinalTax,
    exact: true,
  },
  {
    path: "/app-users",
    component: AppUserDetails,
    exact: true,
  },
  {
    path: "/tenants",
    component: TenantWrapper,
    exact: true,
  },
  {
    path: "/plans",
    component: PlanWrapper,
    exact: true,
  },
  {
    path: "/payments",
    component: PaymentsWrapper,
    exact: true,
  },
  {
    path: "/subscriptions",
    component: CompanySubscriptionWrapper,
    exact: true,
  },
  {
    path: "/orders",
    component: OrderDetailWrapper,
    exact: true,
  },
  {
    path: "/offers",
    component: OfferDetailWrapper,
    exact: true,
  },

  {
    path: "/utils",
    component: UtilityWrapper,
    exact: true,
  },
  {
    path: "/default-settings",
    component: DefaultSettingWrapper,
    exact: true,
  },
  {
    path: "/company-user-details",
    component: NoF,
    exact: true,
  },
];

export default RoutesList;
