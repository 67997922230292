import { Checkbox } from "@mui/material";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { CgClose } from "react-icons/cg";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import AppColor from "../../components/shared/appColor/AppColors";
import IntlMessages from "../../util/IntlMessages";
import ModelSelfFooter from "../shared/Model/ModelSelfFooter";
import ToastMessage from "../shared/ToastMessage";

const PaymentsDetail = ({ openDialog, setOpenDialog, paymentDetailsData }) => {
  PaymentsDetail.propTypes = {
    openDialog: PropTypes.bool,
    setOpenDialog: PropTypes.func,
    paymentDetailsData: PropTypes.object,
  };
  const formik = useFormik({
    initialValues: {
      id: "",
      companyCode: "",
      companyName: "",
      fee: 0,
      description: "",
      amountRefunded: 0,
      captured: "",
      status: "",
      currency: "",
      email: "",
      contact: "",
      amount: 0,
      tax: 0,
      orderId: "",
      method: "",
    },

    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    if (paymentDetailsData) {
      formik.setValues(paymentDetailsData);
    }
  }, [paymentDetailsData]);

  return (
    <>
      <ToastMessage />
      <ModelSelfFooter
        open={openDialog}
        heading={<IntlMessages id="text.payment.detail" />}
        closePopUp={
          <CgClose
            onClick={() => setOpenDialog(false)}
            className="popUpCloseIcon"
          />
        }
        closeFn={() => setOpenDialog(false)}
        maxWidth="xs"
        footer={<span className="DisplayNone">.</span>}>
        <Form
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          className="mdForm"
          onSubmit={formik.handleSubmit}>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="companyCode">Company Code</Label>
                <Input
                  type="text"
                  id="companyCode"
                  name="companyCode"
                  value={formik.values.companyCode}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor="companyName">Company Name</Label>
                <Input
                  type="text"
                  id="companyName"
                  name="companyName"
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="email">Email</Label>
                <Input
                  type="text"
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor="contact">Contact No.</Label>
                <Input
                  type="text"
                  id="contact"
                  name="contact"
                  value={formik.values.contact}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="orderId">Order Id</Label>
                <Input
                  type="text"
                  id="orderId"
                  name="orderId"
                  value={formik.values.orderId}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor="description">Description</Label>
                <Input
                  type="text"
                  id="description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="currency">Currency</Label>
                <Input
                  type="text"
                  id="currency"
                  name="currency"
                  value={formik.values.currency}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor="method">Method</Label>
                <Input
                  type="text"
                  id="method"
                  name="method"
                  value={formik.values.method}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="fee">Fee</Label>
                <Input
                  type="text"
                  id="fee"
                  name="fee"
                  value={formik.values.fee / 100}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor="tax">Tax</Label>
                <Input
                  type="text"
                  id="tax"
                  name="tax"
                  value={formik.values.tax / 100}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor="amount">amount</Label>
                <Input
                  type="text"
                  id="amount"
                  name="amount"
                  value={formik.values.amount / 100}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="amountRefunded">Amount Refunded</Label>
                <Input
                  type="text"
                  id="amountRefunded"
                  name="amountRefunded"
                  value={formik.values.amountRefunded}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor="status">Status</Label>
                <Input
                  type="text"
                  id="status"
                  name="status"
                  value={formik.values.status}
                  onChange={formik.handleChange}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col>
              <Label htmlFor="captured">Captured</Label>
              <Checkbox
                checked={formik?.values?.captured}
                name="captured"
                onChange={formik.handleChange}
                inputProps={{ "aria-label": "controlled" }}
                disabled
              />
            </Col>
          </Row>
          <Row className="FooterForm">
            <Button
              variant="contained"
              color="outline-secondary"
              onClick={() => setOpenDialog(false)}
              className="buttonSpacing">
              <IntlMessages id={`button.text.cancel`} />
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              style={{ backgroundImage: `${AppColor.BackgroundImage}` }}
              className="buttonSpacing"
              disabled>
              <IntlMessages id={`button.text.save`} />
            </Button>
          </Row>
        </Form>
      </ModelSelfFooter>
    </>
  );
};
export default PaymentsDetail;
