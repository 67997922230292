import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { CgClose } from "react-icons/cg";
import { toast } from "react-toastify";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import * as yup from "yup";
import { professionalTaxApi } from "../../apilist/ApiLists";
import AppColor from "../../components/shared/appColor/AppColors";
import { AxiosInstance } from "../../config/AxiosConfig";
import IntlMessages from "../../util/IntlMessages";
import ModelSelfFooter from "../shared/Model/ModelSelfFooter";
import ToastMessage from "../shared/ToastMessage";
import { SlabType, states, month, gender } from "../../util/Helper";

const AddPtSlab = ({ openDialog, setOpenDialog, ptSlabData, reloadFunction, setReloadDataList }) => {
  AddPtSlab.propTypes = {
    openDialog: PropTypes.bool,
    setOpenDialog: PropTypes.func,
    ptSlabData: PropTypes.object,
    reloadFunction: PropTypes.func,
    setReloadDataList: PropTypes.func,
  };
  const ptSlabDetailDataById = ptSlabData && ptSlabData.id;

  let validationSchema = yup.object().shape({
    tax: yup.string().trim().required(""),
    stateCode: yup.string().trim().required(""),
    to: yup.string().trim().required(""),
    interval: yup.string().trim().required(""),
    slabType: yup.string().trim().required(""),
    from: yup.string().trim().required(""),
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      tax: "",
      to: "",
      slabType: "",
      stateCode: "",
      month: "",
      interval: "",
      from: "",
      gender: "",
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema: validationSchema,
  });

  const renderErrorMessage = (field) => {
    return formik.touched[field] && <div className="text-danger">{formik.errors[field]}</div>;
  };

  const CreatePaySlab = async (values) => {
    await AxiosInstance.post(`/${professionalTaxApi}`, values)
      .then((response) => {
        setReloadDataList((prev) => !prev);
        setOpenDialog(false);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const UpdatePaySlab = async (values) => {
    await AxiosInstance.put(`/${professionalTaxApi}/${ptSlabDetailDataById}`, values)
      .then((response) => {
        setOpenDialog(false);
        reloadFunction && reloadFunction(0, 10, "");
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleSubmit = async (values) => {
    if (ptSlabDetailDataById) {
      UpdatePaySlab(values);
    } else {
      CreatePaySlab(values);
    }
  };

  useEffect(() => {
    if (ptSlabData) {
      formik.setValues(ptSlabData);
    }
  }, [ptSlabData]);

  const toggleChecked = (e) => {
    formik.setFieldValue("active", e.target.checked);
  };

  if (formik.isSubmitting && formik.isValidating && !formik.isValid) {
    toast.error("Please fill all the mandatory fields", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }

  return (
    <>
      <ToastMessage />
      <ModelSelfFooter
        open={openDialog}
        heading={ptSlabDetailDataById ? <IntlMessages id="ptSlabDetail.update" /> : <IntlMessages id="ptSlabDetail.add" />}
        closePopUp={
          <CgClose
            onClick={() => setOpenDialog(false)}
            className="popUpCloseIcon"
          />
        }
        closeFn={() => setOpenDialog(false)}
        maxWidth="xs"
        footer={<span className="DisplayNone">.</span>}>
        <Form
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          className="mdForm"
          onSubmit={formik.handleSubmit}>
          <Row>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="stateCode"
                  className="requiredField">
                  <IntlMessages id="text.ptSlabDetail.stateCode" />
                </Label>
                <Input
                  type="select"
                  id="stateCode"
                  name="stateCode"
                  value={formik.values.stateCode}
                  onChange={formik.handleChange}
                  className="input-select-field-css"
                  invalid={formik.touched.stateCode && !formik.values.stateCode}
                  onBlur={formik.handleBlur}>
                  <option value="">-- Select --</option>
                  {states.map((state, index) => (
                    <option
                      key={index}
                      value={state.code}>
                      {state.name} ({state.code})
                    </option>
                  ))}
                </Input>
                {renderErrorMessage("stateCode")}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="enabled"
                  className="requiredField">
                  <IntlMessages id="text.ptSlabDetail.slabType" />
                </Label>
                <Input
                  type="select"
                  id="slabType"
                  name="slabType"
                  value={formik.values.slabType}
                  onChange={formik.handleChange}
                  className="input-select-field-css"
                  invalid={formik.touched.slabType && !formik.values.slabType}
                  onBlur={formik.handleBlur}>
                  <option value="">-- Select --</option>
                  {SlabType.map((slabType, index) => (
                    <option
                      key={index}
                      value={slabType.name}>
                      {slabType.name}
                    </option>
                  ))}
                </Input>
                {renderErrorMessage("slabType")}
              </FormGroup>
            </Col>
          </Row>

          {/* +++++++++++++newwwwww Month++++++++++++++++</> */}

          <Row>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="enabled"
                  className="requiredField">
                  <IntlMessages id="text.ptSlabDetail.interval" />
                </Label>
                <Input
                  type="select"
                  id="interval"
                  name="interval"
                  value={formik.values.interval}
                  onChange={formik.handleChange}
                  className="input-select-field-css"
                  invalid={formik.touched.interval && !formik.values.interval}
                  onBlur={formik.handleBlur}>
                  <option value="">-- Select --</option>
                  {SlabType.map((slabType, index) => (
                    <option
                      key={index}
                      value={slabType.name}>
                      {slabType.name}
                    </option>
                  ))}
                </Input>
                {renderErrorMessage("interval")}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor="month">
                  <IntlMessages id="text.ptSlabDetail.month" />
                </Label>
                <Input
                  type="select"
                  id="month"
                  name="month"
                  value={formik.values.month}
                  onChange={formik.handleChange}
                  className="input-select-field-css"
                  invalid={formik.touched.month && !formik.values.month}
                  onBlur={formik.handleBlur}>
                  <option value="">-- Select --</option>
                  {month.map((month, index) => (
                    <option
                      key={index}
                      value={month.name}>
                      {month.name}
                    </option>
                  ))}
                </Input>
                {renderErrorMessage("month")}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="from">
                  <IntlMessages id="text.ptSlabDetail.from" />
                </Label>
                <Input
                  type="text"
                  id="from"
                  name="from"
                  value={formik.values.from}
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.from && !formik.values.from}
                  onBlur={formik.handleBlur}
                />
                {renderErrorMessage("from")}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor="to">
                  <IntlMessages id="text.ptSlabDetail.to" />
                </Label>
                <Input
                  type="text"
                  id="to"
                  name="to"
                  value={formik.values.to}
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.to && !formik.values.to}
                  onBlur={formik.handleBlur}
                />
                {renderErrorMessage("to")}
              </FormGroup>
            </Col>
          </Row>

          {/* ++++++++++++++++++from+++++++++++++++++++ */}

          <Row>
            <Col>
              <FormGroup>
                <Label
                  htmlFor="enabled"
                  className="requiredField">
                  <IntlMessages id="text.ptSlabDetail.gender" />
                </Label>
                <Input
                  type="select"
                  id="gender"
                  name="gender"
                  value={formik.values.gender}
                  onChange={formik.handleChange}
                  className="input-select-field-css"
                  invalid={formik.touched.gender && !formik.values.gender}
                  onBlur={formik.handleBlur}>
                  <option value="">-- Select --</option>
                  {gender.map((gender, index) => (
                    <option
                      key={index}
                      value={gender.name}>
                      {gender.name}
                    </option>
                  ))}
                </Input>
                {renderErrorMessage("gender")}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor="tax">
                  <IntlMessages id="text.ptSlabDetail.tax" />
                </Label>
                <Input
                  type="text"
                  id="tax"
                  name="tax"
                  value={formik.values.tax}
                  onChange={formik.handleChange}
                  className="input-field-css"
                  invalid={formik.touched.tax && !formik.values.tax}
                  onBlur={formik.handleBlur}
                />
                {renderErrorMessage("tax")}
              </FormGroup>
            </Col>
          </Row>

          {/* ++++++++++++++++++Button++++++++++++++++ */}

          <Row className="FooterForm">
            <Button
              variant="contained"
              color="outline-secondary"
              onClick={() => setOpenDialog(false)}
              className="buttonSpacing">
              <IntlMessages id={`button.text.cancel`} />
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              style={{ backgroundImage: `${AppColor.BackgroundImage}` }}
              className="buttonSpacing">
              <IntlMessages id={`button.text.save`} />
            </Button>
          </Row>
        </Form>
      </ModelSelfFooter>
    </>
  );
};
export default AddPtSlab;
