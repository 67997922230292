const suconstant = `su/`;

// Company Detail Controller
const companyDetails = `${suconstant}companies`;
export const companyDetailsApi = `${companyDetails}`;

// Subscription Plan Controller
const plan = `${suconstant}plans`;
export const createPlanAPI = `${plan}`;

// Tenants Api Controller
const tenants = `${suconstant}tenants`;
export const tenantsApi = `${tenants}`;

// entityCount
const utilCount = `${suconstant}utils`;
export const utilCountApi = `${utilCount}/counts`;

// Company Subscription Controller
export const companySubscriptionApi = `${suconstant}subscriptions`;

// Order Details
export const orderDetailsApi = `${suconstant}orders`;

// offer Details
export const offerDetailsApi = `${suconstant}offers`;

// get All Enums
export const getAllEnums = `${suconstant}utils/getAllEnums`;

// clear master DB and tenant DB changes logs
export const clearMasterDB = `${suconstant}AdminUtility/clean/master-changeLogs?suppressException=true`;
export const clearTenantDB = `${suconstant}AdminUtility/clean/tenants-changeLogs?suppressException=true`;

// Update Schema
export const updateSchemaApi = `${suconstant}AdminUtility/update/schema?suppressException=true`;

// Select Schema
export const selectSchemaApi = `${suconstant}AdminUtility/select/schema?suppressException=true`;

// Payments Details
export const paymentsApi = `${suconstant}payment-detail`;

// professional-tax Details
export const professionalTaxApi = `${suconstant}professional-tax`;

// Default Setting
export const defaultSettingApi = `${suconstant}default-settings`;

// -----------------------Public Apis-------------------------------
const publicApi = `public`;

// Logout Api
export const logoutApi = `${publicApi}/logout`;
