import CloseIcon from "@mui/icons-material/Close";
import { Tooltip } from "@mui/material";
import React from "react";
import { Col, Row, Table } from "reactstrap";
import defaultImg from "../../assets/images/EmpImage.png";
import IntlMessages from "../../util/IntlMessages";
import ModelSelfFooter from "../shared/Model/ModelSelfFooter";
import PropTypes from "prop-types";

const LoggedInUser = ({ openDialog, setOpenDialog }) => {
  LoggedInUser.propTypes = {
    openDialog: PropTypes.bool,
    setOpenDialog: PropTypes.func,
  };
  const myProfile = JSON.parse(sessionStorage.getItem("profile"));

  let roles = "";
  roles += `${myProfile.roles[0]}, `;
  for (let i = 1; i < myProfile.roles.length; i++) {
    roles += `${myProfile.roles[i].split("_")[0]} `;
    if (i === myProfile.roles.length - 1) {
      roles += `${myProfile.roles[i].split("_")[1]}`;
    } else {
      roles += `${myProfile.roles[i].split("_")[1]}, `;
    }
  }

  return (
    <>
      <ModelSelfFooter
        open={openDialog}
        heading={<IntlMessages id="title.myProfile.text" />}
        closePopUp={
          <Tooltip
            title="Close"
            placement="top">
            <CloseIcon
              fontSize="large"
              onClick={() => setOpenDialog(false)}
              className="popUpCloseIcon"
            />
          </Tooltip>
        }
        closeFn={() => setOpenDialog(false)}
        maxWidth="md"
        className="largePopUp">
        <Row className="profileImage">
          <Col className="myProfile">
            <img
              src={defaultImg}
              alt=""
              onerror="this.style.display = 'none';"
              width={70}
              height={80}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Table className="myProfileDataTable">
              <tbody>
                <tr>
                  <td className="tableColon">User Name</td>
                  <td>:</td>
                  <td>{myProfile.username}</td>
                </tr>
                <tr>
                  <td className="tableColon">First Name</td>
                  <td>:</td>
                  <td>{myProfile.firstName}</td>
                </tr>
                <tr>
                  <td className="tableColon">Last Name</td>
                  <td>:</td>
                  <td>{myProfile.lastName}</td>
                </tr>
                <tr>
                  <td className="tableColon">Email</td>
                  <td>:</td>
                  <td>{myProfile.email}</td>
                </tr>
                <tr>
                  <td className="tableColon">User Role</td>
                  <td>:</td>
                  <td>{roles}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </ModelSelfFooter>
    </>
  );
};

export default LoggedInUser;
