import { BiBuildingHouse, BiDetail, BiRupee } from "react-icons/bi";
import { MdOutlineDashboard, MdOutlineLocalOffer, MdOutlineSettings, MdOutlineUnsubscribe, MdOutlineUpdate, MdAttachMoney } from "react-icons/md";
import { RiShieldKeyholeLine } from "react-icons/ri";

const MenuList = [
  {
    title: "sidebar.dashboard",
    path: "/dashboard",
    icon: <MdOutlineDashboard />,
  },
  {
    title: "sidebar.plans",
    path: "/plans",
    icon: <MdOutlineUnsubscribe />,
  },
  {
    title: "sidebar.offers",
    path: "/offers",
    icon: <MdOutlineLocalOffer />,
  },
  {
    title: "sidebar.companies",
    path: "/companies",
    icon: <BiBuildingHouse />,
  },
  {
    title: "sidebar.subscriptions",
    path: "/subscriptions",
    icon: <MdOutlineUnsubscribe />,
  },
  {
    title: "sidebar.orders",
    path: "/orders",
    icon: <BiDetail />,
  },
  {
    title: "sidebar.payments",
    path: "/payments",
    icon: <BiRupee />,
  },
  {
    title: "sidebar.tenants",
    path: "/tenants",
    icon: <RiShieldKeyholeLine />,
  },

  {
    title: "sidebar.utility",
    path: "/utils",
    icon: <MdOutlineUpdate />,
  },
  {
    title: "sidebar.default-settings",
    path: "/default-settings",
    icon: <MdOutlineSettings />,
  },
  {
    title: "sidebar.ptslab",
    path: "/ptslabs",
    icon: <MdAttachMoney />,
  },
];

export default MenuList;
