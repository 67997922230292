import React from "react";
import "./App.css";
import WrapperComponent from "./components/WrapperComponent";
import { IntlProvider, FormattedMessage, FormattedNumber } from "react-intl";
import AppLocale from "./lang";
import Maintenance from "./Maintenance";

function App({ locale }) {
  const currentAppLocale = AppLocale["en"];
  const isUnderMaintenance = process.env.REACT_APP_MAINTENANCE_MODE;
  if (isUnderMaintenance == "true") {
    return <Maintenance />;
  }

  return (
    <>
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        <WrapperComponent />
      </IntlProvider>
    </>
  );
}

export default App;
