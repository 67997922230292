import React, { useState } from "react";
import ComponySubscriptions from "./SubscriptionList";
import UpdateCompanySubscriptions from "./UpdateSubscriptions";

const CompanySubscriptionWrapper = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [reloadDataList, setReloadDataList] = useState(false);

  return (
    <>
      <ComponySubscriptions reloadDataList={reloadDataList} setReloadDataList={setReloadDataList} setIsOpen={setIsOpen} isOpen={isOpen} />
      {isOpen && <UpdateCompanySubscriptions setReloadDataList={setReloadDataList} openDialog={isOpen} setOpenDialog={setIsOpen} />}
    </>
  );
};

export default CompanySubscriptionWrapper;
