import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import Swal from "sweetalert2";
import { AxiosInstance } from "../../config/AxiosConfig";

const DeleteButton = ({ api, Id, refreshList, message }) => {
  const noPointer = { cursor: "pointer" };
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: true,
  });
  const deleteUser = (id) => {
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: `Do you want to delete this ${message}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await AxiosInstance.delete(`${api.delete}/${Id}`)
            .then((res) => {
              refreshList(0, 10, "");
            })
            .catch((err) => {
              swalWithBootstrapButtons.fire({
                position: "center",
                text: err?.response?.data?.message,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire("", `Your ${message} Data is safe`, "error");
        }
      });
  };

  return (
    <>
      <span style={{ textAlign: "center" }}>
        <DeleteIcon onClick={deleteUser} className="deleteIcon" />
      </span>
    </>
  );
};

export default DeleteButton;
