import Axios from "axios";
import { useFormik } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import * as yup from "yup";
import { companyDetailsApi } from "../../apilist/ApiLists";
import hrmsHostLogo250by60 from "../../assets/images/hrmshost-logo-250x60.png";
import AppColor from "../../components/shared/appColor/AppColors";
import IntlMessages from "../../util/IntlMessages";
import "../authentication/authStyle.css";
import "./companyRegister.css";

const CompanyRegistration = () => {
  let validationSchema = yup.object().shape({
    companyName: yup.string().required("Please Enter Company Name"),
    registrationNumber: yup.string().required("Please Enter Registration Number"),
    companyMobile: yup.string().required("Please Enter Company Mobile"),
    companyEmail: yup.string().required("Please Enter Company Email"),
    industryType: yup.string().required("Please Select Industry Type"),
    employeeCountRange: yup.string().required("Please Select Employee Count Range"),
    dateOfEstablishment: yup.string().required("Please Enter Date Of Establishment"),
    addressLine1: yup.string().required("Please Select Address Line1"),
    addressLine2: yup.string().required("Please Select Address Line2"),
    city: yup.string().required("Please Select City"),
    state: yup.string().required("Please Select State"),
    country: yup.string().required("Please Select Country"),
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      active: true,
      companyName: "",
      registrationNumber: "",
      companyMobile: "",
      companyEmail: "",
      industryType: "",
      employeeCountRange: 0,
      dateOfEstablishment: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      country: "",
      loginCreated: true,
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema: validationSchema,
  });

  const renderErrorMessage = (field) => {
    return formik.touched[field] && <div className="text-danger">{formik.errors[field]}</div>;
  };

  const CreateCompanyDetails = async (values) => {
    console.log("values", values);
    await Axios.post(`${process.env.REACT_APP_ERMS_ADMIN_API}${companyDetailsApi}`, values)
      .then((response) => {
        setReloadDataList((prev) => !prev);
        setOpenDialog(false);
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(error.response.data.errors[0]);
      });
  };

  const handleSubmit = async (values) => {
    CreateCompanyDetails(values);
  };

  return (
    <>
      <div className="login-6">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="form-section companyRegistration">
                <div className="logo">
                  <Link href="/">
                    <img
                      src={hrmsHostLogo250by60}
                      onerror="this.style.display = 'none';"
                      alt=""
                    />
                  </Link>
                </div>
                <div className="typing">
                  <h1>
                    <IntlMessages id={`form.registerCompany.tittle`} />
                  </h1>
                </div>
                <div className="login-inner-form">
                  <Form
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    onSubmit={formik.handleSubmit}>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="companyName">
                            <IntlMessages id={`form.company.name`} />
                          </Label>
                          <Input
                            type="text"
                            id="companyName"
                            name="companyName"
                            value={formik.values.companyName}
                            onChange={formik.handleChange}
                          />
                          {renderErrorMessage("companyName")}
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="registrationNumber">
                            <IntlMessages id={`form.registration.number`} />
                          </Label>
                          <Input
                            type="text"
                            id="registrationNumber"
                            name="registrationNumber"
                            value={formik.values.registrationNumber}
                            onChange={formik.handleChange}
                          />
                          {renderErrorMessage("registrationNumber")}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="companyMobile">
                            <IntlMessages id={`form.company.mobile`} />
                          </Label>
                          <Input
                            type="text"
                            id="companyMobile"
                            name="companyMobile"
                            value={formik.values.companyMobile}
                            onChange={formik.handleChange}
                          />
                          {renderErrorMessage("companyMobile")}
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="schema">
                            <IntlMessages id={`form.company.email`} />
                          </Label>
                          <Input
                            type="text"
                            id="companyEmail"
                            name="companyEmail"
                            value={formik.values.companyEmail}
                            onChange={formik.handleChange}
                          />
                          {renderErrorMessage("companyEmail")}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="enabled">
                            <IntlMessages id={`form.industry.type`} />
                          </Label>
                          <Input
                            type="text"
                            id="industryType"
                            name="industryType"
                            value={formik.values.industryType}
                            onChange={formik.handleChange}
                          />
                          {renderErrorMessage("industryType")}
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="enabled">
                            <IntlMessages id={`form.employee.count.range`} />
                          </Label>
                          <Input
                            type="text"
                            id="employeeCountRange"
                            name="employeeCountRange"
                            value={formik.values.employeeCountRange}
                            onChange={formik.handleChange}
                          />
                          {renderErrorMessage("employeeCountRange")}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="enabled">
                            <IntlMessages id={`form.date.of.establishment`} />
                          </Label>
                          <Input
                            type="date"
                            id="dateOfEstablishment"
                            name="dateOfEstablishment"
                            value={formik.values.dateOfEstablishment}
                            onChange={formik.handleChange}
                          />
                          {renderErrorMessage("dateOfEstablishment")}
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="enabled">
                            <IntlMessages id={`form.address.line1`} />
                          </Label>
                          <Input
                            type="text"
                            id="addressLine1"
                            name="addressLine1"
                            value={formik.values.addressLine1}
                            onChange={formik.handleChange}
                          />
                          {renderErrorMessage("addressLine1")}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="enabled">
                            <IntlMessages id={`form.address.line2`} />
                          </Label>
                          <Input
                            type="text"
                            id="addressLine2"
                            name="addressLine2"
                            value={formik.values.addressLine2}
                            onChange={formik.handleChange}
                          />
                          {renderErrorMessage("addressLine2")}
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="enabled">
                            <IntlMessages id={`form.city`} />
                          </Label>
                          <Input
                            type="text"
                            id="city"
                            name="city"
                            value={formik.values.city}
                            onChange={formik.handleChange}
                          />
                          {renderErrorMessage("city")}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="enabled">
                            <IntlMessages id={`form.state`} />
                          </Label>
                          <Input
                            type="text"
                            id="state"
                            name="state"
                            value={formik.values.state}
                            onChange={formik.handleChange}
                          />
                          {renderErrorMessage("state")}
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label htmlFor="enabled">
                            <IntlMessages id={`form.country`} />
                          </Label>
                          <Input
                            type="text"
                            id="country"
                            name="country"
                            value={formik.values.country}
                            onChange={formik.handleChange}
                          />
                          {renderErrorMessage("country")}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button
                      style={{ backgroundColor: AppColor.Purple }}
                      variant="contained"
                      type="submit"
                      className="btn btn-primary btn-lg btn-theme mb-3 mt-2">
                      <IntlMessages id={`form.register.btn`} />
                    </Button>
                  </Form>
                </div>
                <p>
                  <IntlMessages id={`form.already.account`} />
                  <Link
                    to="/login"
                    className="styledLink">
                    <IntlMessages id={`form.login.here`} />
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CompanyRegistration;
