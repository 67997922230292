import Axios from "axios";
import { toast } from "react-toastify";
import IntlMessages from "../util/IntlMessages";
const AxiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_ERMS_ADMIN_API,
  timeout: 200000000,
  mode: "no-cors",
  crossDomain: true,
});

AxiosInstance.interceptors.request.use(
  function (config) {
    config.headers.common["accept"] = "application/text";
    config.headers.common["authorization"] = "Bearer " + sessionStorage.getItem("token");
    return Promise.resolve(config);
  },
  function (error) {
    return Promise.reject(error);
  }
);

AxiosInstance.interceptors.response.use(
  function (response) {
    const { data, message, status } = response.data;
    if (status == "success") {
      if (!!message) {
        toast.success(<IntlMessages id={`${message}`} />);
      }
      return Promise.resolve(data);
    } else {
      if (!!message) {
        toast.error(<IntlMessages id={`${message}`} />);
      }
      return Promise.reject(data);
    }
  },
  function (error) {
    if (error?.request?.status == 500) {
      toast.error(<IntlMessages id={`error.message.internalServer`} />);
      return Promise.reject();
    } else if (error?.request?.status == 401 || error?.request?.status == 402 || error?.request?.status == 403) {
      toast.error(<IntlMessages id={`error.message.sessionExpired`} />);
      setTimeout(() => {
        window.location.replace("/login");
      }, 3000);
      return Promise.reject();
    } else if (error?.request?.status == 400) {
      const { data, message, status } = error.response.data;
      if (!!message) {
        toast.error(<IntlMessages id={`${message}`} />);
      }
      return Promise.reject(data);
    }
  }
);
const CancelToken = Axios.CancelToken;

export { AxiosInstance, CancelToken };
