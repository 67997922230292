import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ToastMessage() {
  return (
    <>
      <ToastContainer className="toast-message" position="top-center" autoClose={5000} hideProgressBar={true} newestOnTop={true} rtl={false} />
    </>
  );
}

export default ToastMessage;
