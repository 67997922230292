import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ToastMessage from "./shared/ToastMessage";
import Login from "../components/authentication/Login";
import Landingpage from "./LandingPage";
import CompanyRegistration from "./company-details/CompanyRegistration";
import PageNotFound from "./authentication/pageNotFound";

const WrapperComponent = () => {
  const [pageError, setPageError] = useState(false);
  return (
    <>
      <div className="WrapperComponent">
        <ToastMessage />
        <Router>
          <Switch>
            <Route
              exact={true}
              path="/login"
              component={Login}
            />
            <Route
              exact={true}
              path="/company-registration"
              component={CompanyRegistration}
            />
            {pageError && (
              <Route
                Path="/pageNotFound"
                component={PageNotFound}
              />
            )}
            <Landingpage setPageError={setPageError} />
          </Switch>
        </Router>
      </div>
    </>
  );
};
export default WrapperComponent;
