import React from "react";
import Utility from "./Utility";

const UtilityWrapper = () => {
  return (
    <>
      <Utility />
    </>
  );
};

export default UtilityWrapper;
