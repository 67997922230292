import React, { useState } from "react";
import PaymentsDetail from "./PaymentsDetail";
import PaymentsList from "./PaymentsList";

const PaymentsWrapper = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [reloadDataList, setReloadDataList] = useState(false);

  return (
    <>
      <PaymentsList reloadDataList={reloadDataList} setReloadDataList={setReloadDataList} setIsOpen={setIsOpen} isOpen={isOpen} />
      {isOpen && <PaymentsDetail setReloadDataList={setReloadDataList} openDialog={isOpen} setOpenDialog={setIsOpen} />}
    </>
  );
};

export default PaymentsWrapper;
