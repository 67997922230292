import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { Col, Row } from "reactstrap";
import { offerDetailsApi } from "../../apilist/ApiLists";
import AppColor from "../../components/shared/appColor/AppColors";
import { AxiosInstance } from "../../config/AxiosConfig";
import DataTableWrapper from "../../tables/DataTableWrapper";
import SearchBox from "../../tables/SearchBox";
import { dateFormatter } from "../../util/Helper";
import IntlMessages from "../../util/IntlMessages";
import DeleteButton from "../shared/DeleteButton";
import TitleHeader from "../shared/pageTitle/TitleHeader";
import AddOfferDetail from "./AddOfferDetail";

const ActionButton = ({ rowData, reloadFunction }) => {
  ActionButton.propTypes = {
    rowData: PropTypes.object,
    reloadFunction: PropTypes.func,
  };
  const [openDialog, setOpenDialog] = useState(false);
  return (
    <span style={{ textAlign: "center" }}>
      <FiEdit
        style={{ cursor: "pointer", color: AppColor.Pink }}
        onClick={() => setOpenDialog(true)}
      />
      {openDialog && (
        <AddOfferDetail
          offerDetailsData={rowData}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          reloadFunction={reloadFunction}
        />
      )}
    </span>
  );
};

const StatusRender = ({ rowData }) => {
  if (rowData.active === true) {
    return (
      <span
        style={{ color: "#07bc0c" }}
        align="center">
        Active
      </span>
    );
  } else if (rowData.active === false) {
    return (
      <span
        style={{ color: "#FF0000" }}
        align="center">
        Inactive
      </span>
    );
  } else {
    return (
      <span
        style={{ color: "#964B00" }}
        align="center">
        --
      </span>
    );
  }
};

const OfferDetailList = ({ setIsOpen, reloadDataList, setReloadDataList }) => {
  OfferDetailList.propTypes = {
    setIsOpen: PropTypes.func,
    reloadDataList: PropTypes.bool,
    setReloadDataList: PropTypes.func,
  };
  const API = {
    delete: `${offerDetailsApi}`,
  };

  const [offerDetail, setOfferDetailData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [statusCode, setStatusCode] = useState("");
  const [searchKey, setSearchKey] = useState("");

  offerDetail.map((offer) => {
    offer.StartDate = dateFormatter(offer.startDate);
    offer.EndDate = dateFormatter(offer.endDate);
  });

  const offerDetailData = async (page, rowsPerPage, search = "") => {
    setLoading(true);
    await AxiosInstance.get(`/${offerDetailsApi}?pageNo=${page}&pageSize=${rowsPerPage}&search=${search}`)
      .then((result) => {
        [...result.resultList[0]].map((row, index) => (row.sno = rowsPerPage * page + (index + 1)));
        setOfferDetailData(result.resultList[0]);
        setTotal(result.numberOfItems);
        setLoading(false);
      })
      .catch((error) => {
        setStatusCode(error?.errorCode);
      });
    setLoading(false);
  };

  const columns = [
    { label: "S. No.", accessor: "sno" },
    { label: "Offer Name", accessor: "offerName" },
    { label: "Offer Description", accessor: "offerDescription" },
    { label: "Start Date", accessor: "StartDate" },
    { label: "End Date", accessor: "EndDate" },
    { label: "Discount (%)", accessor: "discountPercent", type: "number" },
    {
      label: "Offer Status",
      accessor: "StatusRender",
      type: "custom",
      component: StatusRender,
    },
    {
      label: "Action",
      accessor: "custom",
      type: "actionButtonWithUpdate",
      component: ({ rowData }) => {
        return (
          <>
            <Row>
              <Col>
                <ActionButton
                  rowData={rowData}
                  reloadFunction={offerDetailData}
                />
              </Col>
              <Col>
                <DeleteButton
                  api={API}
                  Id={rowData.id}
                  refreshList={offerDetailData}
                  message={"Offer Detail"}
                />
              </Col>
            </Row>
          </>
        );
      },
    },
  ];

  const handlePopup = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    if (reloadDataList) {
      offerDetailData(0, 10, "", "axios.api.success.message");
      setReloadDataList(false);
    }
  }, [reloadDataList]);

  return (
    <>
      <TitleHeader
        shouldShowArrow={false}
        title="HRMS-Admin"
        pageTitle={"offerDetail.title"}
      />
      <SearchBox
        addLink={true}
        actionName={<IntlMessages id={"offerDetail.add.button"} />}
        handleAddBtnClick={handlePopup}
        setSearchKey={setSearchKey}
      />
      <DataTableWrapper
        data={offerDetail}
        columns={columns}
        count={total}
        loading={loading}
        updateData={offerDetailData}
        statusCode={statusCode}
        searchStr={searchKey}
      />
    </>
  );
};

export default OfferDetailList;
