// Emp Address Controller
export const portalEmployeeaddressApi = `address`;
export const portalEmployeeBankDetail = `bank-detail`;
export const portalEmployeeAssets = `asset`;
export const getAllBirthdays = `birthday`;
export const getAllHolidays = `holidays`;
export const getInsuranceDetail = `insurance`;
export const getLeaveRecordList = `leave`;
export const getMyProfileApi = `details`;
export const getEmployeeDetailsApi = `details`;
export const getProjectByEmpId = `project-resource`;
export const getDocumentListApi = `documents`;
export const uploadDocumentApi = `uploadDocument`;
export const deleteDocumentApi = `delete`;
export const downloadDocumentApi = `downloadDocument/`;
export const getAllPayslipsApi = `payslips`;
export const downloadPaySlipApi = `download-payslip/`;
export const downloadEmployeeImageApi = `downloadImage`;
export const getAllEnums = "util/getAllEnums";
const bucket = `s3`;
export const downloadCompanyLogo = `${bucket}/downloadCompanyLogo/`;

// Company Events
export const companyEventsApi = `util/listAllEvents`;

//  Public Api Controller
const publicApis = "public";
export const loginCompany = `${publicApis}/login`;

// Important Links
export const importantLinksApi = `links`;

// Company Detail
export const companyDetailApi = `${publicApis}/company`;

// Forgot Password
export const forgotPasswordApi = `${publicApis}/user/forgotPassword`;
export const updatePasswordApi = `${publicApis}/user/updatePassword`;
export const changePasswordApi = `${publicApis}/user/changePassword`;
