import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import DoneSharpIcon from "@mui/icons-material/DoneSharp";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { Col, Row } from "reactstrap";
import { companySubscriptionApi } from "../../apilist/ApiLists";
import AppColor from "../../components/shared/appColor/AppColors";
import { AxiosInstance } from "../../config/AxiosConfig";
import DataTableWrapper from "../../tables/DataTableWrapper";
import SearchBox from "../../tables/SearchBox";
import { dateFormatter } from "../../util/Helper";
import IntlMessages from "../../util/IntlMessages";
import DeleteButton from "../shared/DeleteButton";
import TitleHeader from "../shared/pageTitle/TitleHeader";
import UpdateCompanySubscriptions from "./UpdateSubscriptions";

const planStatus = ({ rowData }) => {
  if (rowData.active === true) {
    return (
      <>
        <span style={{ color: "rgb(7, 188, 12)", background: "#fff", padding: "0px 1px", borderRadius: " 5px", text: "center" }}>
          <DoneSharpIcon />
        </span>
      </>
    );
  } else {
    return (
      <>
        <span style={{ color: "#FF0000", background: "#fff", padding: "0px 1px", borderRadius: " 5px", align: "center" }}>
          <CloseSharpIcon />
        </span>
      </>
    );
  }
};
const flagtrial = ({ rowData }) => {
  if (rowData.flagTrial === true) {
    return (
      <>
        <span className="trial-flag">
          <FiberManualRecordIcon className="orange-dot" />
        </span>
      </>
    );
  } else {
    return <>--</>;
  }
};

const ActionButton = ({ rowData, reloadFunction }) => {
  ActionButton.propTypes = {
    rowData: PropTypes.object,
    reloadFunction: PropTypes.func,
  };
  const [openDialog, setOpenDialog] = useState(false);
  return (
    <span style={{ textAlign: "center" }}>
      <FiEdit
        style={{ cursor: "pointer", color: AppColor.Pink }}
        onClick={() => setOpenDialog(true)}
      />
      {openDialog && (
        <UpdateCompanySubscriptions
          companySubscriptionData={rowData}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          reloadFunction={reloadFunction}
        />
      )}
    </span>
  );
};

const StatusRender = ({ rowData }) => {
  StatusRender.propTypes = {
    rowData: PropTypes.object,
  };
  if (rowData.daysLeft >= 0) {
    return <div className="statusGreen">{dateFormatter(rowData.planEndDate)}</div>;
  } else {
    return <div className="statusRed">{dateFormatter(rowData.planEndDate)}</div>;
  }
};

const ComponySubscriptions = ({ setIsOpen, reloadDataList, setReloadDataList }) => {
  ComponySubscriptions.propTypes = {
    setIsOpen: PropTypes.func,
    reloadDataList: PropTypes.func,
    setReloadDataList: PropTypes.func,
  };
  const API = {
    delete: `${companySubscriptionApi}`,
  };

  const [compSubscriptionData, setCompSubscriptionData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [statusCode, setStatusCode] = useState("");
  const [searchKey, setSearchKey] = useState("");

  const companySubscriptionsData = async (page, rowsPerPage, search = "") => {
    setLoading(true);
    await AxiosInstance.get(`${companySubscriptionApi}?pageNo=${page}&pageSize=${rowsPerPage}&search=${encodeURIComponent(search)}`)
      .then((result) => {
        [...result.resultList[0]].map((row, index) => (row.sno = rowsPerPage * page + (index + 1)));
        setCompSubscriptionData(result.resultList[0]);
        setTotal(result.numberOfItems);
        setLoading(false);
      })
      .catch((error) => {
        setStatusCode(error?.errorCode);
      });
    setLoading(false);
  };

  const columns = [
    { label: "S. No.", accessor: "sno" },
    { label: "Company Name", accessor: "companyName" },
    { label: "Company Code", accessor: "companyCode" },
    { label: "Plan Code", accessor: "planCode" },
    { label: "Plan Type", accessor: "planType" },
    { label: "Duration Type", accessor: "durationType" },
    {
      label: "Plan Start Date",
      accessor: "planStartDate",
      type: "dateFun",
      component: StatusRender,
    },
    {
      label: "Plan End Date",
      accessor: "planEndDate",
      type: "custom",
      component: StatusRender,
    },
    { label: "Days Left", accessor: "daysLeft", type: "number" },
    { label: "Plan Status", accessor: "active", type: "custom", component: planStatus },
    { label: "Trial", accessor: "flagTrial", type: "custom", component: flagtrial },
    {
      label: "Action",
      accessor: "custom",
      type: "actionButtonWithUpdate",
      component: ({ rowData }) => {
        return (
          <>
            <Row>
              <Col className="action-padding">
                <ActionButton
                  rowData={rowData}
                  reloadFunction={companySubscriptionsData}
                />
              </Col>
              <Col className="action-padding">
                <DeleteButton
                  api={API}
                  Id={rowData.id}
                  refreshList={companySubscriptionsData}
                  message={" Address"}
                />
              </Col>
            </Row>
          </>
        );
      },
    },
  ];

  const handlePopup = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    if (reloadDataList) {
      companySubscriptionsData(0, 10, "");
      setReloadDataList(false);
    }
  }, [reloadDataList]);

  return (
    <>
      <TitleHeader
        shouldShowArrow={false}
        title="HRMS-Admin"
        pageTitle={"company.subscription.title"}
      />
      <SearchBox
        actionName={<IntlMessages id={"add.subscription"} />}
        handleAddBtnClick={handlePopup}
        setSearchKey={setSearchKey}
      />
      <DataTableWrapper
        data={compSubscriptionData}
        columns={columns}
        count={total}
        loading={loading}
        updateData={companySubscriptionsData}
        statusCode={statusCode}
        searchStr={searchKey}
      />
    </>
  );
};

export default ComponySubscriptions;
