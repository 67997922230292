import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import DoneSharpIcon from "@mui/icons-material/DoneSharp";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { Col, Row } from "reactstrap";
import { companyDetailsApi } from "../../apilist/ApiLists";
import AppColor from "../../components/shared/appColor/AppColors";
import { AxiosInstance } from "../../config/AxiosConfig";
import DataTableWrapper from "../../tables/DataTableWrapper";
import SearchBox from "../../tables/SearchBox";
import DeleteButton from "../shared/DeleteButton";
import TitleHeader from "../shared/pageTitle/TitleHeader";
import AddCompanyDetails from "./AddCompanyDetails";

const ActionButton = ({ rowData, reloadFunction }) => {
  ActionButton.propTypes = {
    rowData: PropTypes.object.isRequired,
    reloadFunction: PropTypes.func.isRequired,
  };
  const [openDialog, setOpenDialog] = useState(false);
  return (
    <span style={{ textAlign: "center" }}>
      <FiEdit
        style={{ cursor: "pointer", color: AppColor.Pink }}
        onClick={() => setOpenDialog(true)}
      />
      {openDialog && (
        <AddCompanyDetails
          companyData={rowData}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          reloadFunction={reloadFunction}
        />
      )}
    </span>
  );
};

const loginCreate = ({ rowData }) => {
  if (rowData.loginCreated === true) {
    return (
      <span className="renderStatusIcon renderStatusIconGreen">
        <DoneSharpIcon />
      </span>
    );
  } else {
    return (
      <span className="renderStatusIcon renderStatusIconRed">
        <CloseSharpIcon />
      </span>
    );
  }
};

const activeStatus = ({ rowData }) => {
  if (rowData.active === true) {
    return (
      <span className="renderStatusIcon renderStatusIconGreen">
        <DoneSharpIcon />
      </span>
    );
  } else {
    return (
      <span className="renderStatusIcon renderStatusIconRed">
        <CloseSharpIcon />
      </span>
    );
  }
};

const CompanyDetails = ({ setIsOpen, reloadDataList, setReloadDataList }) => {
  CompanyDetails.propTypes = {
    setIsOpen: PropTypes.func,
    reloadDataList: PropTypes.bool,
    setReloadDataList: PropTypes.func,
  };
  const API = {
    delete: `${companyDetailsApi}`,
  };

  const [companyDetails, setCompanyDetails] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [statusCode, setStatusCode] = useState("");
  const [searchKey, setSearchKey] = useState("");

  const GetCompanyDetails = async (page, rowsPerPage, search = "") => {
    setLoading(true);
    await AxiosInstance.get(`${companyDetailsApi}?pageNo=${page}&pageSize=${rowsPerPage}&search=${search}`)
      .then((response) => {
        [...response.resultList[0]].map((row, index) => (row.sno = rowsPerPage * page + (index + 1)));
        setCompanyDetails(response.resultList[0]);
        setTotal(response.numberOfItems);
        setLoading(false);
      })
      .catch((error) => {
        setStatusCode(error?.errorCode);
      });
    setLoading(false);
  };

  const columns = [
    { label: "S. No.", accessor: "sno" },
    {
      label: "Company Name",
      accessor: "companyName",
    },
    {
      label: "Company Code",
      accessor: "companyCode",
    },
    {
      label: "Regi. No.",
      accessor: "registrationNumber",
    },
    {
      label: "Mobile",
      accessor: "companyMobile",
    },
    {
      label: "Email",
      accessor: "companyEmail",
    },
    {
      label: "Industry",
      accessor: "industryType",
    },
    {
      label: "emp Count",
      accessor: "employeeCountRange",
    },
    {
      label: "DOE",
      accessor: "dateOfEstablishment",
      type: "dateFun",
    },
    {
      label: "Date Created",
      accessor: "dateCreated",
      type: "dateFun",
    },
    {
      label: "Date Login Created",
      accessor: "dateLoginCreated",
      type: "dateFun",
    },
    { label: "Login Created", accessor: "loginCreated", type: "custom", component: loginCreate },
    { label: "Active Status", accessor: "active", type: "custom", component: activeStatus },
    {
      label: "Date Modified",
      accessor: "dateModified",
      type: "dateFun",
    },
    {
      label: "City",
      accessor: "city",
    },
    {
      label: "State",
      accessor: "state",
    },
    {
      label: "Country",
      accessor: "country",
    },
    {
      label: "FirstName",
      accessor: "adminFirstName",
    },
    {
      label: "LastName",
      accessor: "adminLastName",
    },
    {
      label: "Action",
      accessor: "custom",
      type: "actionButtonWithUpdate",
      component: ({ rowData }) => {
        return (
          <>
            <Row>
              <Col md={5}>
                <ActionButton
                  rowData={rowData}
                  reloadFunction={GetCompanyDetails}
                />
              </Col>
              <Col md={5}>
                <DeleteButton
                  api={API}
                  Id={rowData.companyCode}
                  refreshList={GetCompanyDetails}
                  message={"company"}
                />
              </Col>
            </Row>
          </>
        );
      },
    },
  ];

  const handlePopup = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    if (reloadDataList) {
      GetCompanyDetails(0, 10, "");
      setReloadDataList(false);
    }
  }, [reloadDataList]);

  return (
    <>
      <TitleHeader
        shouldShowArrow={false}
        title="HRMS-Admin"
        pageTitle={"company.detail.title"}
      />
      <SearchBox
        handleAddBtnClick={handlePopup}
        setSearchKey={setSearchKey}
      />
      <DataTableWrapper
        data={companyDetails}
        columns={columns}
        count={total}
        loading={loading}
        updateData={GetCompanyDetails}
        statusCode={statusCode}
        searchStr={searchKey}
      />
    </>
  );
};

export default CompanyDetails;
