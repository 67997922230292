import { CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Button, Col, Form, FormGroup, Row } from "reactstrap";
import Swal from "sweetalert2";
import { clearMasterDB, clearTenantDB, updateSchemaApi, selectSchemaApi } from "../../apilist/ApiLists";
import { AxiosInstance } from "../../config/AxiosConfig";
import IntlMessages from "../../util/IntlMessages";
import TitleHeader from "../shared/pageTitle/TitleHeader";

const Utility = () => {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      updateString: "",
      selectString: "",
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values) => {
    if (!values.updateString == "") {
      const reqBody = {
        query: values.updateString,
      };

      setLoading(true);
      await AxiosInstance.post(`/${updateSchemaApi}`, reqBody)
        .then((response) => {
          setLoading(false);
        })
        .catch((errors) => {
          setLoading(false);
        });
    } else if (!values.selectString == "") {
      const reqBody = {
        query: values.selectString,
      };

      setLoading(true);
      await AxiosInstance.post(`/${selectSchemaApi}`, reqBody)
        .then((response) => {
          setLoading(false);
        })
        .catch((errors) => {
          setLoading(false);
        });
    }
  };

  const masterAPI = `${clearMasterDB}`;
  const tenantAPI = `${clearTenantDB}`;

  const messageForMaster = "Master DB change Logs";
  const messageForTenant = "Tenant DB change Logs";

  const master = "Master";
  const tenant = "Tenant";

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: true,
  });
  const clearDBChangeLogs = (message1, message2, api) => {
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: `Do you want to Clear this ${message1}!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, clear it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await AxiosInstance.put(`${api}`)
            .then((res) => {})
            .catch((err) => {
              swalWithBootstrapButtons.fire({
                position: "center",
                text: err?.response?.data?.message,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire("", `Your ${message2} Data is safe`, "error");
        }
      });
  };

  return (
    <>
      <TitleHeader
        shouldShowArrow={false}
        title="HRMS-Admin"
        pageTitle={"sidebar.utility"}
      />
      <div className="main-body">
        <Row className="body-content">
          <Col
            className="col-button"
            md={6}>
            <Button
              className="btn-color"
              variant="contained"
              onClick={() => clearDBChangeLogs(messageForMaster, master, masterAPI)}>
              <div>
                <IntlMessages id={"button.utility.cleanMasterDB"} />
              </div>
            </Button>
          </Col>
          <Col
            className="col-button"
            md={6}>
            <Button
              className="btn-color"
              variant="contained"
              onClick={() => clearDBChangeLogs(messageForTenant, tenant, tenantAPI)}>
              <div>
                <IntlMessages id={"button.utility.cleanTenantDB"} />
              </div>
            </Button>
          </Col>
        </Row>
      </div>

      <div className="main-body-update-schema">
        <Form
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          className="mdForm"
          //</div>onSubmit={formik.handleSubmit}
        >
          <Row className="body-content">
            <FormGroup>
              <div className="form-group-update-schema">
                <label className="label-style">
                  <IntlMessages id={"text.utility.updateSchema"} />
                </label>
                <textarea
                  type="text"
                  name="updateString"
                  className="form-control"
                  rows="5"
                  id="updateString"
                  value={formik.values.updateString}
                  onChange={formik.handleChange}
                  required></textarea>
              </div>
            </FormGroup>
            <Col className="col-submit-button">
              <Button
                color="primary"
                variant="contained"
                //type="submit"
                className="buttonSpacing"
                onClick={formik.handleSubmit}
                disabled={loading}>
                <IntlMessages id={"button.utility.updateSchema"} />
                <div>{loading ? <CircularProgress className="progress-bar"></CircularProgress> : null}</div>
              </Button>
            </Col>
            <IntlMessages id={"text.message.updateSqlExample"} />
          </Row>
        </Form>
      </div>
      <div className="main-body-select-schema">
        <Form
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          className="mdForm"
          //</div>onSubmit={handleSubmit}
        >
          <Row className="body-content">
            <FormGroup>
              <div className="form-group-select-schema">
                <label className="label-style">
                  <IntlMessages id={"text.utility.selectSchema"} />
                </label>
                <textarea
                  type="text"
                  name="selectString"
                  className="form-control"
                  rows="5"
                  id="selectString"
                  value={formik.values.selectString}
                  onChange={formik.handleChange}
                  required></textarea>
              </div>
            </FormGroup>
            <Col className="col-submit-button">
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className="buttonSpacing"
                onClick={formik.handleSubmit}
                disabled={loading}>
                <IntlMessages id={"button.utility.selectSchema"} />
                <div>{loading ? <CircularProgress className="progress-bar"></CircularProgress> : null}</div>
              </Button>
            </Col>
            <IntlMessages id={"text.message.selectSqlExample"} />
          </Row>
        </Form>
      </div>
    </>
  );
};

export default Utility;
