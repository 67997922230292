import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "reactstrap";
import AppColor from "../components/shared/appColor/AppColors";
import PropTypes from "prop-types";

const SearchBox = ({ addLink, actionName, handleAddBtnClick, hideSearchBox, setSearchKey }) => {
  SearchBox.propTypes = {
    addLink: PropTypes.string.isRequired,
    actionName: PropTypes.string.isRequired,
    handleAddBtnClick: PropTypes.func.isRequired,
    hideSearchBox: PropTypes.bool.isRequired,
    setSearchKey: PropTypes.func.isRequired,
  };

  const [search, setSearch] = useState("");
  const [isReset, setIsReset] = useState(false);
  const inputRef = useRef();
  const searchEmp = (e) => {
    setSearch(e?.target?.value);
    setIsReset(false);
  };

  const resetField = async () => {
    setSearchKey("");
    setSearch("");
    setIsReset(true);
  };
  useEffect(() => {
    isReset ? inputRef.current.focus() : null;
  }, [isReset]);

  const onAddBtnClick = () => {
    handleAddBtnClick();
  };

  return (
    <>
      <Row className="searchBoxRow">
        <div className="searchBoxWrapper">
          <Row>
            <Col className="mb-3">
              {addLink && (
                <Button
                  className="addDetailsBtn"
                  onClick={onAddBtnClick}
                  style={{ backgroundImage: `${AppColor.BackgroundImage}` }}>
                  {actionName}
                </Button>
              )}
            </Col>
            {!hideSearchBox && (
              <Col className="mb-3">
                <div className="searchBox">
                  <div className="SearchForm">
                    <input
                      className="form-control searchInputField"
                      type="text"
                      placeholder="Search"
                      value={search}
                      ref={inputRef}
                      onChange={searchEmp}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          setSearchKey(search.trim());
                        }
                      }}
                    />
                    <Button
                      outline
                      disabled={!search}
                      className="searchResetBtn searchButton"
                      onClick={() => {
                        setSearchKey(search.trim());
                      }}>
                      <SearchOutlinedIcon />
                    </Button>
                  </div>
                  <Button
                    color="primary"
                    style={{
                      backgroundImage: "linear-gradient(to bottom, #db3390, #d73131c2)",
                    }}
                    className="searchResetBtn mx-2"
                    onClick={() => resetField()}>
                    <RestartAltOutlinedIcon />
                  </Button>
                </div>
              </Col>
            )}
          </Row>
        </div>
      </Row>
    </>
  );
};

export default SearchBox;
