import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { MdArrowForwardIos } from "react-icons/md";
import IntlMessages from "../../../util/IntlMessages";

const TitleHeader = ({ title, pageTitle, shouldShowArrow = true }) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title ? title : "HRMS-Admin"}</title>
      </Helmet>
      <div className="mb-3 PageTitleBar">
        {shouldShowArrow && (
          <h4 className="arrowColor m-1">
            <MdArrowForwardIos />
          </h4>
        )}
        <h4>
          <IntlMessages id={pageTitle ? pageTitle : "defaultHeader"} />
        </h4>
      </div>
    </HelmetProvider>
  );
};

export default TitleHeader;
