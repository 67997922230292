import React, { useState } from "react";
import PtSlabs from "./PtSlabs";
import AddPtSlab from "./AddPtSlab";

function professinalTax() {
  const [isOpen, setIsOpen] = useState(false);
  const [reloadDataList, setReloadDataList] = useState(false);
  return (
    <>
      <PtSlabs
        reloadDataList={reloadDataList}
        setReloadDataList={setReloadDataList}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />
      {isOpen && (
        <AddPtSlab
          setReloadDataList={setReloadDataList}
          openDialog={isOpen}
          setOpenDialog={setIsOpen}
        />
      )}
    </>
  );
}

export default professinalTax;
