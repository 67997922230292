import React, { useState } from "react";
import OrderDetailList from "./OrderDetailList";
import UpdateOrderDetail from "./UpdateOrderDetail";

const OrderDetailWrapper = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [reloadDataList, setReloadDataList] = useState(false);

  return (
    <>
      <OrderDetailList reloadDataList={reloadDataList} setReloadDataList={setReloadDataList} setIsOpen={setIsOpen} isOpen={isOpen} />
      {isOpen && <UpdateOrderDetail setReloadDataList={setReloadDataList} openDialog={isOpen} setOpenDialog={setIsOpen} />}
    </>
  );
};

export default OrderDetailWrapper;
