import CloseIcon from "@mui/icons-material/Close";
import { Tooltip } from "@mui/material";
import Axios from "axios";
import { useFormik } from "formik";
import PasswordField from "material-ui-password-field";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import * as yup from "yup";
import { changePasswordApi } from "../../redux/ApiLists";
import IntlMessages from "../../util/IntlMessages";
import ModelSelfFooter from "../shared/Model/ModelSelfFooter";

const ChangePassword = ({ openChangePassword, setChangePassword }) => {
  ChangePassword.propTypes = {
    openChangePassword: PropTypes.bool,
    setChangePassword: PropTypes.func,
  };
  const [loading, setLoading] = useState(false);
  let validationSchema = yup.object().shape({
    username: yup.string().trim().required("Please Enter Username"),
    oldPassword: yup.string().trim().required("Please Enter Current Password "),
    password: yup
      .string()
      .matches(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/, "Password at least have 8 character containing one special character, Uppercase, Lowercase and Digit")
      .required("Please Enter New Password"),
    confirmNewPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Please Re-Enter New Password"),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      oldPassword: "",
      password: "",
      confirmNewPassword: "",
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema: validationSchema,
  });

  const renderErrorMessage = (field) => {
    return formik.touched[field] && <div className="text-danger mb-2">{formik.errors[field]}</div>;
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    await Axios.post(`${process.env.REACT_APP_ERMS_ADMIN_API}${changePasswordApi}`, values, {
      headers: { "Content-Type": "application/json", Authorization: "Bearer " + sessionStorage.getItem("token") },
    })
      .then((response) => {
        toast.success("Password Change Successfully");
        setTimeout((token) => {
          sessionStorage.clear(token);
          window.location.href = "/login";
        }, 3000);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
    setLoading(false);
  };
  const Profile = JSON.parse(sessionStorage.getItem("profile"));
  const userName = Profile?.username;
  useEffect(() => {
    formik.setFieldValue("username", userName);
  }, []);
  return (
    <>
      <ModelSelfFooter
        open={openChangePassword}
        heading={<IntlMessages id="button.text.changePassword" />}
        closePopUp={
          <Tooltip
            title="Close"
            placement="top">
            <CloseIcon
              fontSize="large"
              onClick={() => setChangePassword(false)}
              className="popUpCloseIcon"
            />
          </Tooltip>
        }
        closeFn={() => setChangePassword(false)}
        maxWidth="md"
        footer={<span className="DisplayNone">.</span>}>
        <Form
          className="mdForm forgotPassword"
          onSubmit={formik.handleSubmit}>
          <Row className="mb-2">
            <Col
              md={5}
              className="formLabelRight">
              <Label className="requiredField">User Name</Label>
            </Col>
            <Col md={7}>
              <FormGroup>
                <Input
                  name="username"
                  id="username"
                  onChange={formik.handleChange}
                  value={formik.values.username.trim()}
                  disabled={formik.values.username}
                />
                {renderErrorMessage("username")}
              </FormGroup>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col
              md={5}
              className="formLabelRight">
              <Label className="requiredField">Current Password</Label>
            </Col>
            <Col md={7}>
              <FormGroup>
                <PasswordField
                  name="oldPassword"
                  id="oldPassword"
                  onChange={formik.handleChange}
                  value={formik.values.oldPassword.trim()}
                />
                {renderErrorMessage("oldPassword")}
              </FormGroup>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col
              md={5}
              className="formLabelRight">
              <Label className="requiredField">New Password</Label>
            </Col>
            <Col md={7}>
              <FormGroup>
                <PasswordField
                  name="password"
                  id="password"
                  onChange={formik.handleChange}
                  value={formik.values.password.trim()}
                  floatingLabelText="Enter your New Password"
                />
                {renderErrorMessage("password")}
              </FormGroup>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col
              md={5}
              className="formLabelRight">
              <Label className="requiredField">Confirm New Password</Label>
            </Col>
            <Col md={7}>
              <FormGroup>
                <PasswordField
                  name="confirmNewPassword"
                  onChange={formik.handleChange}
                  value={formik.values.confirmNewPassword.trim()}
                  floatingLabelText="Enter your Confirm New Password"
                />
                {renderErrorMessage("confirmNewPassword")}
              </FormGroup>
            </Col>
          </Row>

          <Row className="FooterForm">
            <Button
              variant="contained"
              color="outline-secondary"
              onClick={() => setChangePassword(false)}
              className="buttonSpacing">
              <IntlMessages id={`button.text.cancel`} />
            </Button>
            <Button
              color="primary"
              variant="contained"
              className="buttonSpacing"
              type="submit"
              disabled={loading}>
              <IntlMessages id={`button.text.changePassword`} />
            </Button>
          </Row>
        </Form>
      </ModelSelfFooter>
    </>
  );
};

export default ChangePassword;
