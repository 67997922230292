import React from "react";
import { useState } from "react";
import AddUpdateDefaultSetting from "./AddUpdateDefaultSetting";
import DefaultSettingCard from "./DefaultSettingCard";

const DefaultSettingWrapper = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <DefaultSettingCard setIsOpen={setIsOpen} isOpen={isOpen} />
      {isOpen && <AddUpdateDefaultSetting openDialog={isOpen} setOpenDialog={setIsOpen} />}
    </>
  );
};
export default DefaultSettingWrapper;
