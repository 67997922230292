import React, { useState } from "react";
import AddTenant from "./AddTenant";
import TenantList from "./TenantList";

const TenantWrapper = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <TenantList setIsOpen={setIsOpen} />
      {isOpen && <AddTenant openDialog={isOpen} setOpenDialog={setIsOpen} />}
    </>
  );
};

export default TenantWrapper;
