import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import DoneSharpIcon from "@mui/icons-material/DoneSharp";
import React, { useState } from "react";
import { FiEdit } from "react-icons/fi";
import { Col, Row } from "reactstrap";
import { paymentsApi } from "../../apilist/ApiLists";
import AppColor from "../../components/shared/appColor/AppColors";
import { AxiosInstance } from "../../config/AxiosConfig";
import DataTableWrapper from "../../tables/DataTableWrapper";
import SearchBox from "../../tables/SearchBox";
import TitleHeader from "../shared/pageTitle/TitleHeader";
import PaymentsDetail from "./PaymentsDetail";

const Captured = ({ rowData }) => {
  if (rowData.captured === true) {
    return (
      <>
        <span style={{ color: "rgb(7, 188, 12)", background: "#fff", padding: "0px 1px", borderRadius: " 5px" }} text="center">
          <DoneSharpIcon />
        </span>
      </>
    );
  } else {
    return (
      <>
        <span style={{ color: "#FF0000", background: "#fff", padding: "0px 1px", borderRadius: " 5px" }} align="center">
          <CloseSharpIcon />
        </span>
      </>
    );
  }
};

const ActionButton = ({ rowData, reloadFunction }) => {
  const [openDialog, setOpenDialog] = useState(false);
  return (
    <span style={{ textAlign: "center" }}>
      <FiEdit style={{ cursor: "pointer", color: AppColor.Pink }} onClick={() => setOpenDialog(true)} />
      {openDialog && <PaymentsDetail paymentDetailsData={rowData} openDialog={openDialog} setOpenDialog={setOpenDialog} reloadFunction={reloadFunction} />}
    </span>
  );
};

const PaymentsList = ({ setIsOpen }) => {
  const [payments, setPayments] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [statusCode, setStatusCode] = useState("");
  const [searchKey, setSearchKey] = useState("");

  const getPaymentDetails = async (page, rowsPerPage, search = "") => {
    setLoading(true);
    await AxiosInstance.get(`/${paymentsApi}?pageNo=${page}&pageSize=${rowsPerPage}&search=${search}`)
      .then((response) => {
        [...response.resultList[0]].map((row, index) => (row.sno = rowsPerPage * page + (index + 1)));
        setPayments(response.resultList[0]);
        setTotal(response.numberOfItems);
        setLoading(false);
      })
      .catch((error) => {
        setStatusCode(error?.errorCode);
      });
    setLoading(false);
  };

  const columns = [
    { label: "S. No.", accessor: "sno" },
    {
      label: "Company Code",
      accessor: "companyCode",
    },
    {
      label: "Company Name",
      accessor: "companyName",
    },
    {
      label: "Order Number",
      accessor: "orderNumber",
    },
    {
      label: "Razorpay Payment Id",
      accessor: "id",
    },
    {
      label: "Razorpay Order Id",
      accessor: "razorpayOrderId",
    },
    {
      label: "Email",
      accessor: "email",
    },
    {
      label: "Contact",
      accessor: "contact",
    },

    {
      label: "Description",
      accessor: "description",
    },
    {
      label: "Currency",
      accessor: "currency",
    },
    {
      label: "Method",
      accessor: "method",
    },
    {
      label: "Fee",
      accessor: "fee",
      type: "currency_Paisa",
    },
    {
      label: "Tax",
      accessor: "tax",
      type: "currency_Paisa",
    },
    {
      label: "Amount",
      accessor: "amount",
      type: "currency_Paisa",
    },
    {
      label: "Amount Refunded",
      accessor: "amountRefunded",
      type: "currency_Paisa",
    },
    {
      label: "Status",
      accessor: "status",
    },
    {
      label: "Action",
      accessor: "custom",
      type: "actionButtonWithUpdate",
      component: ({ rowData }) => {
        return (
          <>
            <Row>
              <Col>
                <ActionButton rowData={rowData} reloadFunction={getPaymentDetails} />
              </Col>
            </Row>
          </>
        );
      },
    },
  ];

  const handlePopup = () => {
    setIsOpen(true);
  };

  return (
    <>
      <TitleHeader shouldShowArrow={false} title="HRMS-Admin" pageTitle={"title.payment"} />
      <SearchBox addLink={false} handleAddBtnClick={handlePopup} setSearchKey={setSearchKey} />
      <DataTableWrapper data={payments} columns={columns} count={total} loading={loading} updateData={getPaymentDetails} statusCode={statusCode} searchStr={searchKey} />
    </>
  );
};

export default PaymentsList;
