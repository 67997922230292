import { Checkbox, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { CgClose } from "react-icons/cg";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { companySubscriptionApi } from "../../apilist/ApiLists";
import AppColor from "../../components/shared/appColor/AppColors";
import { AxiosInstance } from "../../config/AxiosConfig";
import IntlMessages from "../../util/IntlMessages";
import ModelSelfFooter from "../shared/Model/ModelSelfFooter";
import ToastMessage from "../shared/ToastMessage";
import PropTypes from "prop-types";

const UpdateCompanySubscriptions = ({ openDialog, setOpenDialog, companySubscriptionData, reloadFunction }) => {
  UpdateCompanySubscriptions.propTypes = {
    openDialog: PropTypes.bool,
    setOpenDialog: PropTypes.func,
    reloadFunction: PropTypes.func,
    companySubscriptionData: PropTypes.array,
  };
  const compSubscriptionDataById = companySubscriptionData?.id;

  const formik = useFormik({
    initialValues: {
      id: "",
      companyCode: "",
      companyName: "",
      dateModified: "",
      planStartDate: "",
      planEndDate: "",
      active: true,
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values) => {
    await AxiosInstance.put(`/${companySubscriptionApi}/${compSubscriptionDataById}`, values).then((response) => {
      setOpenDialog(false);
      reloadFunction && reloadFunction(0, 10, "");
    });
  };

  useEffect(() => {
    if (companySubscriptionData) {
      formik.setValues(companySubscriptionData);
    }
  }, [companySubscriptionData]);
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return (
    <>
      <ToastMessage />
      <ModelSelfFooter
        open={openDialog}
        heading={<IntlMessages id="title.subscription" />}
        closePopUp={
          <CgClose
            onClick={() => setOpenDialog(false)}
            className="popUpCloseIcon"
          />
        }
        closeFn={() => setOpenDialog(false)}
        maxWidth="xs"
        footer={<span className="DisplayNone">.</span>}>
        <Form
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          className="mdForm"
          onSubmit={formik.handleSubmit}>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="companyName">Company Name</Label>
                <Input
                  type="text"
                  id="companyName"
                  name="companyName"
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label htmlFor="planStartDate">Plan Start Date</Label>
                <Input
                  type="date"
                  id="planStartDate"
                  name="planStartDate"
                  value={formik.values.planStartDate}
                  onChange={formik.handleChange}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor="planEndDate">Plan End Date</Label>
                <Input
                  type="date"
                  id="planEndDate"
                  name="planEndDate"
                  value={formik.values.planEndDate}
                  onChange={formik.handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label htmlFor="planEndDate">Plan Status</Label>
              <Checkbox
                checked={formik?.values?.active}
                name="active"
                onChange={formik.handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Col>
          </Row>
          <Row className="FooterForm">
            <Button
              variant="contained"
              color="outline-secondary"
              onClick={() => setOpenDialog(false)}
              className="buttonSpacing">
              <IntlMessages id={`button.text.cancel`} />
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              style={{ backgroundImage: `${AppColor.BackgroundImage}` }}
              className="buttonSpacing">
              <IntlMessages id={`button.text.save`} />
            </Button>
          </Row>
        </Form>
      </ModelSelfFooter>
    </>
  );
};
export default UpdateCompanySubscriptions;
